import React from "react";
// import React, { useState, useEffect } from "react";
// import Particles from 'react-particles-js';
// import Image from 'react-bootstrap/Image'



function HeroAnimation1() {
    // const [setIndex] = useState(0);
    // const [index, setIndex] = useState(0);
    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };
    return (
            
        <div className="content-wrapper">
                {/* <Particles 
                    style={{
                        width: '100%',
                    }}
                    params={{
                        "particles": {
                            "number": {
                                "value": 11,
                                "density": {
                                    "enable": true,
                                    "value_area": 800
                                }
                            },
                            "line_linked": {
                                "enable": false
                            },
                            "move": {
                                "speed": 8,
                                "out_mode": "out"
                            },
                            "shape": {
                                "type": [
                                    "image",
                                    "circle"
                                ],
                                "image": [
                                    {
                                        "src": "/images/all-badges/web3vm_badge_0013.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_1127.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_0020.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_1163.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_0016.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_1595.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_0902.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_1035.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/web3vm_badge_1306.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/16260_front-1-l-400x.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/cap-white-gray-emb-r-400x.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/cap-black-l-400x.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/17675_front-3-r-400x.png",
                                        "height": 100,
                                        "width": 100
                                    },
                                    {
                                        "src": "/images/all-badges/17673_front-3-l-400x.png",
                                        "height": 100,
                                        "width": 100
                                    },



                                ]
                            },
                            "color": {
                                "value": "#3f566b",
                                "opacity": "0.1"
                            },
                            "size": {
                                "value": 75,
                                "random": true,
                                "anim": {
                                    "enable": true,
                                    "speed": 10,
                                    "size_min": 50,
                                    "sync": false
                                }
                            }
                        },
                        "retina_detect": false
                    }}/> */}


{/*             <div className="container HeroAnimation1">
                <div className="banner-wrapper">
                    <div className="row align-items-center">
                            <div className="crypto-3d-graphic animated" data-animation="fadeInUpShorter" data-animation-delay="1.7s">
                                <Image src="/images/logos/Swag0x_logo_master-H-FFFFFF-1000.png" className="graphic-3d-img mx-auto d-block" fluid alt="The NFT Print Store" />
                            </div>
                        <div className="col-lg-6 col-md-12 move-first">
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content pt-5 introFjallaOne">

                                <div className="reallybigtextgray" data-animation="fadeInUpShorter" data-animation-delay="1.5s">It's more than Crypto...</div>
                                <div className="reallybigtextgray mb-5" data-animation="fadeInUpShorter" data-animation-delay="1.5s">It's part of the Fourth Industrial Revolution.</div>



                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
         


        </div >
        );
}

export default HeroAnimation1