import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import {  Col } from "react-bootstrap";

import {
  HorizontalBar
} from 'react-chartjs-2';

// const nowEpoch = parseInt(Date.now() / 1000);
// const getEpoch = () => parseInt(Date.now() / 1000);

// const updateAmount = (amount,rate, cb) =>  setInterval(() => {
//     console.log("updateAmount",amount,rate);
//     cb(amount-(rate*5))
//     updateAmount(amount-(rate*5),rate, cb)
//   }, 5000)

const shop5digits = (x,y) =>  parseFloat(x.toFixed(y ? y :5))

const getData = (x,c) => ({
  labels: ['Free Badge Faucet'],
  datasets: [{
    label: 'Amount available to collect free badges:',
    backgroundColor: '#001aff',
    borderColor: '#001afa',
    borderWidth: 1,
    hoverBackgroundColor: '#4455eb',
    hoverBorderColor: '#4455ef',
    data: [x]
  }]
})

//read more here why we use this https://stackoverflow.com/a/59274004/10602320

function useInterval(callback, delay) {
    const intervalRef = React.useRef();
    const callbackRef = React.useRef(callback);

    // Remember the latest callback:
    //
    // Without this, if you change the callback, when setInterval ticks again, it
    // will still call your old callback.
    //
    // If you add `callback` to useEffect's deps, it will work fine but the
    // interval will be reset.
  
    React.useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
  
    // Set up the interval:
  
    React.useEffect(() => {
      if (typeof delay === 'number') {
        intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
  
        // Clear interval if the components is unmounted or the delay changes:
        return () => window.clearInterval(intervalRef.current);
      }
    }, [delay]);
    
    // Returns a ref to the interval ID in case you want to clear it manually:
    return intervalRef;
  }


export default function StreamDetails({stream}) {
  const [amountLeftToWithdraw, setAmountLeft] = useState(stream.amountLeftToWithdraw);
  const [barData, setBar] = useState(getData(0,stream.currency));
  const options = {
    scales: {
      xAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,
          steps: 10,
          stepValue: 10,
          min: 0,
          max: 100
        }
      }]
    },
    title: {
      display: true,
      text: 'X5 Badges Faucet'
    },
    tooltips: {
      enabled: false
    }
  }

    const intervalRef = useInterval(() => {
        // console.log('amountLeftToWithdraw', amountLeftToWithdraw, !stream.streamFinished);
        
         if (amountLeftToWithdraw >= 0 && !stream.streamFinished) {
            setAmountLeft(amountLeftToWithdraw+stream.rate)
            setBar(getData(shop5digits((amountLeftToWithdraw + stream.rate) / 10 ** 18, 4), stream.currency))
        } else {
             setAmountLeft(amountLeftToWithdraw + stream.rate)
             setBar(getData(shop5digits((amountLeftToWithdraw + stream.rate) / 10 ** 18, 4), stream.currency))
            window.clearInterval(intervalRef.current);
        }
    }, 1000);

    const titleText =  amountLeftToWithdraw ? shop5digits(amountLeftToWithdraw/10**18,4) +" " +stream.currency + " Ready for X5 Badges" : "X5Badge"
  return (<Col>
        <Helmet>
            <title>
            {titleText}
            </title>
        </Helmet>
    <h3>Relay balance (Ready for Distribution}) {shop5digits(stream.amountLeftToWithdraw/10**18,2)}  {stream.currency}</h3>
    <h3>Amount Streamed so far: {shop5digits(stream.amountStreamed/10**18,2)} {stream.currency}</h3>
    <HorizontalBar 
      height={40}
      data={barData}
      options = {
        options
      }
    />
    <h4>Badge Faucet Balance (to pick team and get first badge) {shop5digits(amountLeftToWithdraw/10**18,4)} {stream.currency}</h4>
  </Col>)
}