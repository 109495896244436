import React from "react";
// import React, { useState, useEffect } from "react";
// import Particles from 'react-particles-js';
import Image from "react-bootstrap/Image";

function HeroAnimation2() {
  // const [setIndex] = useState(0);
  // const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex, e) => {
  //     setIndex(selectedIndex);
  // };
  return (
    <div className="content-wrapper">
      <div className="container HeroAnimation2">
        <div className="banner-wrapper">
          <div className="row align-items-center">
            <div
              className="crypto-3d-graphic animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="1.7s"
            >
              <Image
                src="/images/logos/the-nft-ps-logo-orig-trans-white.png"
                className="graphic-3d-img mx-auto d-block"
                fluid
                alt="thenftprintstore"
              />
            </div>
            <div className="col-lg-6 col-md-12 move-first"></div>

            <div className="col-lg-12 col-md-12">
              <div className="banner-content introFjallaOne">
                <div
                  className="bigtextwhite mb-3"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.5s"
                >
                  a Web3 marketplace that uses digital tokens to order crypto
                  swag.
                </div>

                <div
                  className="smtextyellow mb-5"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.5s"
                >
                  <span role="img" aria-label="locked-lock">
                    &#128272;
                  </span>
                  &nbsp;The NFT Print Store does not require usernames or passwords; only a
                  public Web3 address and digital signature is necessary. If you
                  do not have a Web3 account you will receive one instantly and
                  for free.*
                </div>

                <div
                  className="xsmtextwhite mb-5 d-block animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.6s"
                >
                  The NFT Print Store collects no information from you apart from your
                  shipping address and contact information to alert you about
                  the status of your swag order.
                </div>

                <a
                  href="#magic1"
                  className="mr-2 mb-2 btn2 btn2-xl btn-gradient-orange btn-glow animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.8s"
                >
                  Get a Swag0x Team Badge
                </a>
                <br />
                <br />
                <br />
                <a
                  href="#magic2"
                  className="mr-2 mb-2 btn2 btn2-xl btn-gradient-orange btn-glow animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.8s"
                >
                  Get a Level 1 Skill Badge
                </a>
                <br />
                <br />
                <br />

                <div
                  className="xxsmtextdesc mt-5 mb-5 d-block animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.6s"
                >
                  <emwhite>
                    * Web3 is a javascript library of protocols that uses
                    Peer-to-Peer (P2P) technology to connect to decentralized
                    file storage systems and the Ethereum public blockchain.
                    Web3 allows for private financial transactions and allows
                    you to interact with a public system of node computers and
                    blockchains.
                    <br />
                    <hr></hr>
                    ** You will receive one ERC20 token called an{" "}
                    <emyellow>X5T</emyellow> and one non-fungible team-badge
                    token (NFT) NFTs are unique blockchain managed tokens which
                    are yours to keep forever. (the Etherem blockchain requires
                    Ether to pay for the transaction processing).{" "}
                    <emyellow>
                      The X5T token is used as digital currency to print and pay
                      for digital art and NFTs. You will need to add funds to
                      your Web3 account to purchase X5T tokens and swag.{" "}
                    </emyellow>
                    You add funds instantly from the The NFT Print Store website using a
                    debit card, credit card, or ApplePay account to purchase X5T
                    The NFT Print Store tokens.
                  </emwhite>{" "}
                </div>

                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container HeroAnimation2b">
        <div className="banner-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="banner-content centerFjallaOne">
                <div
                  className="crypto-3d-graphic animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.7s"
                >
                  <Image
                    src="/images/logos/the-nft-ps-stack-orig-trans-royal-white.png"
                    className="graphic-3d-img mx-auto d-block"
                    fluid
                    alt="The NFT Print Store"
                  />

                  <div className="row centerFjallaOne mb-5">
                    <div className="col-lg-12 mb-5"></div>
                    <div className="bigtextyellow">
                      Earn or Buy team and skill badges and print them to swag.
                    </div>
                    <div className="mdtextwhite">
                      Print digital artwork to cool items that are shipped
                      worldwide to you.
                    </div>

                    <div className="mdtextdesc">
                      <emwhite>
                        The NFT Print Store <emyellow>supports artists</emyellow> by using
                        the public Ethereum&nbsp;
                        <i className="fab fa-ethereum fa-1x"></i> blockchain to
                        verify ownership before it is printed.
                      </emwhite>
                    </div>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-12 col-lg-4">
                    <span className="fa-stack fa-3x">
                      <i className="fas fa-circle fa-stack-2x text-dark "></i>
                      <i className="far far fa-image fa-stack-1x fa-inverse "></i>
                    </span>
                    <div className="mdtextwhite">
                      The NFT Print Store allows the printing of digital artwork.{" "}
                    </div>
                    <p className="text-muted">
                      Copyright protections are important to artists. The NFT Print Store
                      checks the users account to first make sure the art is
                      owened by the user.
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <span className="fa-stack fa-3x">
                      <i className="fas fa-circle fa-stack-2x text-dark "></i>
                      <i className="fab fa-ethereum fa-stack-1x fa-inverse "></i>
                    </span>
                    <div className="mdtextwhite">
                      The Ethereum block chain used to verify the ownership of
                      digital artwork.
                    </div>
                    <p className="text-muted">
                      The public Ethereum blockchain &nbsp;
                      <i className="fab fa-ethereum fa-1x"></i>&nbsp; contains a
                      permanent registy of all digital assest owned by a
                      specific Web3 account.{" "}
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <span className="fa-stack fa-3x">
                      <i className="fas fa-circle fa-stack-2x text-dark "></i>
                      <i className="fas fa-link fa-stack-1x fa-inverse "></i>
                    </span>
                    <div className="mdtextwhite">
                      Ownership of digital artwork is is managed by a Web3
                      account.
                    </div>
                    <p className="text-muted">
                      Artwork ownership is contained in a smart-contract called
                      a NFT (non-fungible token). It is an Ethereum &nbsp;
                      <i className="fab fa-ethereum fa-1x"></i>&nbsp; standard
                      ERC721 ownership token.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroAnimation2;
