import React from 'react';




// const namedTeams = ["arborland", "hills_overthere", "summerfield", "cactus_flats", "manorland", "sunset_bay", "castaway_bay", "meltenberg", "tall_falls", "cypress_bay", "moon_mountain", "unicorn_spinland", "distant_bigrock", "mountain_valley", "wide_falls", "distant_dunes", "new_promiseland", "windmill_hill", "high_forest", "nights_bridge", "windy_hills", "hills_overhere", "old_promiseland", "yester_year"]


export default function Badges() {
    return (<h2> About </h2>);
}