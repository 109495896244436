import React from "react";
import { withRouter } from "react-router";

import {
  Button,
  Card,
} from "react-bootstrap";

//one pay deposit button link
//https://pay.sendwyre.com/?dest=ethereum:0xd277a99c0d08ded3bdb253024bff81e41496465c&destCurrency=ETH&accountId=AC-7AG3W4XH4N2&paymentMethod=debit-card

function OneCard({
  title,
  price,
  image,
  slug,
  history,
  type
}) {

    // const [modalShow, setModalShow] = React.useState(false);
    // const [countryCode, setCountryCode] = React.useState("US");
    // const [prices, setPrices] = React.useState({});


    React.useEffect( () => {
      // /v3.0/catalogue/prodigi%20direct/destination/{countryCode}/prices
      // postData("/v3.0/catalogue/prodigi%20direct/destination/"+countryCode+"/prices", {
      //   countryCode,
      //   skus: [ defaultSku ]
      // }).then((res)=>{
      //   console.log('setPrices',res);
      //   setPrices(res.data)
      // })
    }, [] )
  return (
    <React.Fragment>
      <Card style={{ width: "100%", marginTop: 10 }} onClick={() => history.push("/"+(type==="team" ?"team":"level")+'badge/' + slug)}>
        <Card.Img className="py-lg-4 px-lg-2" style={{ marginTop: 5 }} variant="top" src={image} />
        <Card.Body>
          <Card.Title className="text-truncate" style={{ fontSize: 14}}>{title}</Card.Title>
          <Card.Text>{price} Finney</Card.Text>
            <Button variant="primary" onClick={() => (0.001)} className='hvr-grow-rotate'>
              Select {type && type === "team" ? "Team" : "Level"}
            </Button>
        </Card.Body>
    </Card>
    </React.Fragment>
  );
}


export default withRouter(OneCard)
