import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
import {
    Carousel
} from "react-bootstrap";

function Hero() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (

        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/logos/the-nft-ps-logo-orig-trans-royal-blue.png"
                    alt="The NFT Print Store"
                />
                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/logos/the-nft-ps-logo-orig-trans-white.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/logos/the-nft-ps-logo-orig-trans-red.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/logos/the-nft-ps-logo-orig-trans-black.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/logos/the-nft-store-color-stacked-01-400w.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/logos/the-nft-store-color-stacked-04-400w.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>


        </Carousel>



    );
}

export default Hero