// /index.js
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
const config = {
    apiKey: "AIzaSyBzOf6Gi62O1Ug5TVef3bvigm38Z2xgGmo",
    authDomain: "swag0x-com.firebaseapp.com",
    databaseURL: "https://swag0x-com.firebaseio.com",
    projectId: "swag0x-com",
    storageBucket: "swag0x-com.appspot.com",
    messagingSenderId: "637980710505",
    appId: "1:637980710505:web:88b334def16b16940f34f8",
    measurementId: "G-S3S4GPGRKT"
};
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}
const auth = firebase.auth();
export {
    auth,
    firebase
};
