import React, { useEffect } from "react";
// import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import {
  Button,
  Col,
  Card,
  Row,
  Accordion,
  ListGroup,
  Alert,
  Image,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
  // Modal,
  // ButtonGroup,
  Badge as SweetInfo,
} from "react-bootstrap";
// import CryptoCompare from "react-crypto-compare";
import countryList from "react-select-country-list";
import Web3 from "web3";

// import dai from "./abis/dai.js"
// import usdc from "./abis/usdc.js"
// import chainlink from "./abis/chainlink.js"

// const abis = {dai, chainlink, usdc};
// import Web3Provider, { useWeb3Context, Web3Consumer } from "web3-react";
const Tx = require("ethereumjs-tx").Transaction;

const cc = require("cryptocompare");
cc.setApiKey(
  "5eb9da642a6f2d6b932710aca0bd8fdba1ceaf1a1376a0fac63d05b86877635e"
);
const provider = new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82"
);
const web3 = new Web3(Web3.givenProvider || provider);

// const IPFS = require('ipfs')

// const { urlSource } = IPFS
// const ipfs = IPFS('ipfs.infura.io', '5001', { protocol: 'https' })

// function uploadMockups() {
//     console.log(M1);

// }

var x5Exchange = new web3.eth.Contract(
  [
    {
      inputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1",
          type: "uint256",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
      ],
      name: "Burn",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1",
          type: "uint256",
        },
      ],
      name: "Mint",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0In",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1In",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount0Out",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount1Out",
          type: "uint256",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
      ],
      name: "Swap",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint112",
          name: "reserve0",
          type: "uint112",
        },
        {
          indexed: false,
          internalType: "uint112",
          name: "reserve1",
          type: "uint112",
        },
      ],
      name: "Sync",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      constant: true,
      inputs: [],
      name: "DOMAIN_SEPARATOR",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "MINIMUM_LIQUIDITY",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "PERMIT_TYPEHASH",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "address", name: "", type: "address" },
        { internalType: "address", name: "", type: "address" },
      ],
      name: "allowance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "value", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "address", name: "to", type: "address" }],
      name: "burn",
      outputs: [
        { internalType: "uint256", name: "amount0", type: "uint256" },
        { internalType: "uint256", name: "amount1", type: "uint256" },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "factory",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "getReserves",
      outputs: [
        { internalType: "uint112", name: "_reserve0", type: "uint112" },
        { internalType: "uint112", name: "_reserve1", type: "uint112" },
        { internalType: "uint32", name: "_blockTimestampLast", type: "uint32" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "_token0", type: "address" },
        { internalType: "address", name: "_token1", type: "address" },
      ],
      name: "initialize",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "kLast",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "address", name: "to", type: "address" }],
      name: "mint",
      outputs: [
        { internalType: "uint256", name: "liquidity", type: "uint256" },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "nonces",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "value", type: "uint256" },
        { internalType: "uint256", name: "deadline", type: "uint256" },
        { internalType: "uint8", name: "v", type: "uint8" },
        { internalType: "bytes32", name: "r", type: "bytes32" },
        { internalType: "bytes32", name: "s", type: "bytes32" },
      ],
      name: "permit",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "price0CumulativeLast",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "price1CumulativeLast",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "address", name: "to", type: "address" }],
      name: "skim",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "uint256", name: "amount0Out", type: "uint256" },
        { internalType: "uint256", name: "amount1Out", type: "uint256" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "swap",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "sync",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "token0",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "token1",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "value", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "value", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  "0xE67D81F542aAbB5A65eC05Ba45a831a63d9ab036"
);

var x5tRouter = new web3.eth.Contract(
  [
    {
      inputs: [
        {
          internalType: "uint256",
          name: "amountOut",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "reserveIn",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "reserveOut",
          type: "uint256",
        },
      ],
      name: "getAmountIn",
      outputs: [
        {
          internalType: "uint256",
          name: "amountIn",
          type: "uint256",
        },
      ],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "amountIn",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "reserveIn",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "reserveOut",
          type: "uint256",
        },
      ],
      name: "getAmountOut",
      outputs: [
        {
          internalType: "uint256",
          name: "amountOut",
          type: "uint256",
        },
      ],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "amountOutMin",
          type: "uint256",
        },
        {
          internalType: "address[]",
          name: "path",
          type: "address[]",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "deadline",
          type: "uint256",
        },
      ],
      name: "swapExactETHForTokens",
      outputs: [
        {
          internalType: "uint256[]",
          name: "amounts",
          type: "uint256[]",
        },
      ],
      stateMutability: "payable",
      type: "function",
    },
  ],
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
);

const useStateWithLocalStorage = (localStorageKey) => {
  const parsed = !!localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : [];
  const [cart, setCart] = React.useState(parsed);
  React.useEffect(() => {
    console.log("cart updated", cart);
    localStorage.setItem(localStorageKey, JSON.stringify(cart));
  }, [cart, localStorageKey]);

  return [cart, setCart];
};

// const infuraEndpoint = "https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82"
// const web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint))

// const corsFix = "https://cors-anywhere.herokuapp.com/"

const endpoint = "https://swag0x-com.firebaseapp.com/api";
// const endpoint = "http://localhost:5000/api"

async function postData(url = "", data = {}, type = "POST") {
  // Default options are marked with *
  let options = {
    method: type, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    crossDomain: true,
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  };
  if (type !== "GET") options["body"] = JSON.stringify(data);
  const response = await fetch(endpoint + url, options);
  return await response.json(); // parses JSON response into native JavaScript objects
}

function getShipping(data) {
  const e = "/shipping/quote";
  return postData(e, data);
}

// const daiContract = new web3.eth.Contract(abis["dai"].abi, abis["dai"].address)
// const usdcContract = new web3.eth.Contract(abis["usdc"].abi, abis["usdc"].address)
// const chainlinkContract = new web3.eth.Contract(abis["chainlink"].abi, abis["chainlink"].address)
// const contracts = { dai: daiContract, chainlink: chainlinkContract, usdc: usdcContract }

// async function getBalanceOf(currency, account) {
//     if (!contracts[currency]) return 0

//     console.log("getBalanceOf", currency, contracts[currency], account);

//     if (!contracts[currency].methods.balanceOf) return 0

//     const xbalance = await contracts[currency].methods.balanceOf(account).call()

//     return xbalance
// }

function sendOrder(order) {
  return postData("/order/send", order)
    .then((res) => {
      console.log("order created ", res);
    })
    .catch((res) => {
      // @TODO add error to handle when the order fails on the UI
      console.error("order error ", res);
    });
}

// function verifyOrder(tx) {

//     return postData("/order/confirm", { tx })

// }

const toFixed = (x, t = 2) => {
  const y = parseFloat(x);
  if (y) {
    return y.toFixed(t);
  } else return x;
};

function Cart({
  history,
  showLogin,
  account,
  openUniswap,
  openWyre,
  balance,
  x5tokenContract,
  updateBalance,
  closeUniswap,
  showUniswap,
  ethBalance,
  ethRate,
  gasPrice,
}) {
  const [cart, setCart] = useStateWithLocalStorage("X5BadgeStore");
  const [shipping, setShipping] = React.useState(0);
  // const [extra, setExtra] = React.useState(0);
  const [shippingOptions, setShippingOptions] = React.useState([]);
  const [address, setAddress] = React.useState({ country: "US" });
  const [coupon, setCoupon] = React.useState("");
  const [x5t, setX5T] = React.useState(0);
  const [saved, setSaved] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [currentTab, setTab] = React.useState("0");
  const [allDone, setAllDone] = React.useState("");
  // const [modalShow, setModalShow] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error] = React.useState("");
  // const [error, setError] = React.useState("");
  console.log(account, "wallet account");
  console.log("cart", cart);
  const countries = countryList().getData();

  useEffect(
    (w) => {
      // console.log("countries", countries);
      const xtotal =
        cart && cart.length
          ? Math.round(
              cart.reduce((acc, cur) => {
                if (!cur || !cur.quantity) return cur;
                acc += cur.quantity * cur.currentVariant.price;
                return acc;
              }, 0)
            )
          : 0;
      setTotal(xtotal);
      // x5Exchange.methods.getEthToTokenInputPrice(1).call().then((x) =>{
      //     console.log(web3.utils.fromWei(x, 'ether'), parseFloat(web3.utils.fromWei(x, 'ether')));

      //     setX5T(parseFloat(web3.utils.fromWei(x, 'ether')) )
      // })
      // subscribeEvents();
    },
    [cart]
  );

  // const subscribeEvents = () => {
  //   // updateBalance(account);
  //   var subscription = web3.eth
  //     .subscribe(
  //       "logs",
  //       {
  //         address: "0xE67D81F542aAbB5A65eC05Ba45a831a63d9ab036",
  //         topics: [
  //           "0xcd60aa75dea3072fbc07ae6d7d856b5dc5f4eee88854f5b4abf7b680ef8bc50f",
  //         ],
  //       },
  //       (error, result) => {
  //         console.log("callback", error, result);
  //         updateBalance(account);
  //       }
  //     )
  //     .on("data", (log) => {
  //       console.log("data", log);
  //       updateBalance(account);
  //       closeUniswap();
  //     })
  //     .on("changed", (log) => {
  //       console.log("logs changes", log);
  //       updateBalance(account);
  //     });
  //   // unsubscribes the subscription
  //   // subscription.unsubscribe(function (error, success) {
  //   //     if (success)
  // };

  const changeQuantity = (cartIndex, quantity) => {
    let xcart = [...cart];
    xcart[cartIndex].quantity = Number(quantity) > 0 ? Number(quantity) : 1;
    console.log(cartIndex, quantity, xcart[cartIndex]);
    setCart(xcart);
    resetInputs();
  };

  const resetInputs = () => {
    setSaved(0);
    setShipping(0);
    setShippingOptions([]);
    setProcessing(false);
  };

  const removeProduct = (cartIndex) => {
    let xcart = [...cart];
    delete xcart[cartIndex];
    xcart = xcart.filter((item) => item);
    resetInputs();
    setCart(xcart);
  };
  // https://sebs.github.io/etherscan-api/#eth_gettransactionreceipt
  const getShippingOptions = () => {
    console.log(address);
    setLoading(true);
    let shipData = {
      recipient: {
        address1: address.address,
        city: address.city,
        country_code: address.country,
        state_code: address.state,
        zip: address.zip_code,
      },
      items: [],
    };

    // FIX THIS !!!!
    // cart.map((item, i) => {
    //   const tmp = {
    //     quantity: item.quantity,
    //     variant_id: item.currentVariant.id,
    //   };
    //   if (item.quantity) shipData.items.push(tmp);
    // });

    getShipping(shipData)
      .then((res) => {
        let xrate = 0;
        if (res.code === 200) {
          res.result[0].checked = true;
          setShippingOptions(res.result);
          xrate = parseFloat(res.result[0].rate);
          setShipping(xrate);
          // setExtra(rate/5)
        } else {
          console.error("can't get shipping rate");
        }
        console.log("ship quote ", res);
        setLoading(false);

        const totalToPayETH = toFixed((total + xrate) / ethRate, 3);

        console.log(
          "convert to wei to uniswap",
          xrate,
          shipping,
          totalToPayETH,
          web3.utils.toWei(totalToPayETH.toString(), "ether")
        );

        x5Exchange.methods
          .getReserves()
          .call()
          .then((x) => {
            console.log(" reserve x5t------------- ", x);
            x5tRouter.methods
              .getAmountOut(
                web3.utils.toBN(
                  web3.utils.toWei(totalToPayETH.toString(), "ether")
                ),
                x._reserve1,
                x._reserve0
              )
              .call()
              .then((y) => {
                console.log(" x5tRouter ------------- ", y);
                setX5T(Number(y));
              })
              .catch(console.error);
            // x5tRouter.methods.swapExactETHForTokens
          })
          .catch(console.error);
        // x5Exchange.methods.getEthToTokenInputPrice(web3.utils.toWei(totalToPayETH.toString(), 'ether')).call().then((x) => {
        //     console.log("getEthToTokenInputPrice",x, parseFloat(web3.utils.fromWei(x, 'ether')));

        //     // setX5T(parseFloat(web3.utils.fromWei(x, 'ether')))
        //     setX5T(x)
        // }).catch(console.error)
      })
      .catch((res) => {
        setLoading(false);
        console.error("order error ", res);
      });
  };

  const updateAddress = (key, value) => {
    const newAddress = { ...address };
    newAddress[key] = value;
    setAddress(newAddress);
  };

  const payCrypto = async () => {
    // const priceInCrypto = 0;
    // Web3.send
    setProcessing(true);
    const accounts = await web3.eth.getAccounts();
    console.log("accounts", accounts, account);
    if (!account) {
      setProcessing(false);
      // return setError("You must " + <Alert.Link href="#">an example link</Alert.Link>+" you crypto wallet! e.g: MetaMask")
    }
    const userAccount = account;
    const swag0XAccount = "0x18AfCF776A76f90f27cE03e66567068ac0FF1e7e";
    // var gasPrice = 33;//or get with web3.eth.gasPrice
    var gasLimit = 60000;
    console.log("web3.eth.gasPrice", web3.eth.gasPrice);
    // const ethPrice = await api.stats.ethprice()
    // const totalToPayUSD = total + shipping;
    // const totalToPayETH = totalToPayUSD / ethRate;
    // const totalToPayX5T = Math.round(toFixed(totalToPayETH, 3) / 0.0002);
    // x5tokenContract
    x5tokenContract.methods
      .transfer(swag0XAccount, x5t)
      .send({
        from: userAccount,
        gasLimit: web3.utils.toHex(gasLimit),
        gasPrice: web3.utils.toHex(gasPrice * 1e8),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        post_send_callback("", hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log("confirmation", receipt);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        //check tx

        setAllDone(receipt.transactionHash);
        setProcessing(true);

        // verifyOrder(receipt.transactionHash)
        // .then((res) => {
        //     console.log('order confirmed ', res);
        //     if (res.orderConfirmed) {
        //         setAllDone(receipt.transactionHash)
        //         setProcessing(true)
        //     }
        // })
        // .catch((res) => {
        //     // @TODO add error to handle when the order fails on the UI
        //     console.error('order error ', res);
        // })
        updateBalance(account);
      });
    // cc.price('ETH', 'USD')
    //     .then(prices => {
    //         console.log(prices)
    //         // -> { USD: 1100.24 }
    //         setRate(prices.USD)
    //         //convert total usd to eth
    //         const totalToPayUSD = total + shipping + extra
    //         const totalToPayETH = totalToPayUSD / prices.USD
    //         const amountToSend = web3.utils.toWei( totalToPayETH.toString(), 'ether' );
    //         const totalToPayX5T = Math.round(totalToPayETH / 0.0002)

    //         var rawTransaction = {
    //             "from": userAccount,
    //             // "nonce": web3.toHex(Math.random() + new Date()),
    //             "gasPrice": web3.utils.toHex(gasPrice * 1e9),
    //             "gasLimit": web3.utils.toHex(gasLimit),
    //             "to": swag0XAccount,
    //             "value": amountToSend,
    //             "chainId": 4 //remember to change this
    //         };

    //         console.log("rawTransaction totalToPayX5T", rawTransaction, totalToPayX5T);

    //         // web3.eth.sendTransaction({
    //         //     from: userAccount,
    //         //     to: swag0XAccount,
    //         //     value: amountToSend,
    //         //     gasLimit: web3.utils.toHex(gasLimit),
    //         //     gasPrice: web3.utils.toHex(gasPrice * 1e9),
    //         // }, post_send_callback);
    //     })
    //     .catch(
    //         (e) =>{
    //             setProcessing(false)
    //             console.error(e)
    //         })
  };


  // FIX THIS
  // 1 3 5 6 7 9
  // const positionsList = {
  //   M1: {
  //     //badge front normal
  //     files: [
  //       {
  //         type: "front",
  //         url: "https://ipfs.infura.io/ipfs/Qmeqnq8JzUzXCd8umyq8tcdLJ2qHAEnveJgBz3UJNjazve",
  //       },
  //     ],
  //   },
  //   M3: {
  //     //M3 badge Big on Back
  //     files: [
  //       {
  //         type: "back",
  //         url: "https://ipfs.infura.io/ipfs/Qmeqnq8JzUzXCd8umyq8tcdLJ2qHAEnveJgBz3UJNjazve",
  //       },
  //     ],
  //   },
  //   M5: {
  //     //M6.Small on Front Top Left (team or level badge)

  //     files: [
  //       {
  //         type: "front",
  //         url: "https://ipfs.infura.io/ipfs/Qmeqnq8JzUzXCd8umyq8tcdLJ2qHAEnveJgBz3UJNjazve",
  //       },
  //     ],
  //   },
  //   M6: {
  //     //M7.Small on Front Top Left Swag0x - Big on Back (team or level badge)

  //     files: [
  //       {
  //         type: "front",
  //         url: "https://ipfs.infura.io/ipfs/QmUvMqHDVoqj92cndtJtHZrZqogBNQTngC34D3cUu2hYDB",
  //       },
  //       {
  //         type: "back",
  //         url: "https://ipfs.infura.io/ipfs/Qmeqnq8JzUzXCd8umyq8tcdLJ2qHAEnveJgBz3UJNjazve",
  //       },
  //     ],
  //   },
  //   M7: {
  //     // badge on front and swag0x on back
  //     files: [
  //       {
  //         type: "front",
  //         url: "https://ipfs.infura.io/ipfs/Qmeqnq8JzUzXCd8umyq8tcdLJ2qHAEnveJgBz3UJNjazve",
  //       },
  //       {
  //         type: "back",
  //         url: "https://ipfs.infura.io/ipfs/QmUvMqHDVoqj92cndtJtHZrZqogBNQTngC34D3cUu2hYDB",
  //       },
  //     ],
  //   },
  //   M9: {
  //     //M5.Small Swag0x on Front Top Left  - Big Swag0x on back

  //     files: [
  //       {
  //         type: "front",
  //         url: "https://ipfs.infura.io/ipfs/QmUvMqHDVoqj92cndtJtHZrZqogBNQTngC34D3cUu2hYDB",
  //       },
  //       {
  //         type: "back",
  //         url: "https://ipfs.infura.io/ipfs/QmUvMqHDVoqj92cndtJtHZrZqogBNQTngC34D3cUu2hYDB",
  //       },
  //     ],
  //   },
  // };

  // FIX THIS !!!!!!!
  // function addPositions(item) {
  //   const type = item.layout?.type;
  //   console.log("addPositions", item, type);
  //   if (!type)
  //     return {
  //       quantity: item.quantity,
  //       variant_id: item.variant_id,
  //       files: [
  //         {
  //           url: item.image,
  //         },
  //       ],
  //     };
  //   const positionType = parseInt(type[type.length - 1]);
  //   let currentPosition = positionsList["M" + positionType];
  //   currentPosition.quantity = item.quantity;
  //   currentPosition.variant_id = item.variant_id;
  //   console.log("addPositions", positionType, currentPosition);
  //   if (positionType < 6) {
  //     currentPosition.files[0].url = item.image;
  //     return currentPosition;
  //   } else if (positionType === 6) {
  //     currentPosition.files[1].url = item.image;
  //     return currentPosition;
  //   } else if (positionType === 7) {
  //     currentPosition.files[0].url = item.image;
  //     return currentPosition;
  //   } else return currentPosition;
  // }

  const post_send_callback = (errors, txid) => {
    // indicateMetamaskPopup(true);
    if (errors) {
      //transaction failed!
      console.log({ message: "There was an error." }, "warning", errors);
      // failure_callback();
      setProcessing(false);
    } else {
      // const url = "/order/confirm";
      console.log("transaction confirmed", txid);
      const selectedShipping = shippingOptions.find(
        (item) => parseFloat(item.rate) === shipping
      );
      let shipData = {
        account,
        tx: txid,
        coupon,
        email: address.email_address,
        ethRate,
        x5t,
        total,
        shippingCost: shipping,
        shipping: selectedShipping ? selectedShipping.id : "STANDARD",
        recipient: {
          address1: address.address,
          city: address.city,
          country_code: address.country,
          state_code: address.state,
          zip: address.zip_code,
        },
        items: [],
      };

      // cart.map((item, i) => {
        // const tmp = {
        //     "quantity": item.quantity,
        //     "variant_id": item.currentVariant.id,
        //     "files": [{
        //         "url": "https://ipfs.infura.io/ipfs/QmbeKefmCkotEpRBsTvfUjCeB3MP9art5oYu9LpGcCFBRq"
        //     }]
        // }
      //   const tmp = addPositions({
      //     quantity: item.quantity,
      //     variant_id: item.currentVariant.id,
      //     image: item.image,
      //     layout: item.currentVariant.layout,
      //   });
      //   if (item.quantity) shipData.items.push(tmp);
      // });
      sendOrder(shipData);
      setProcessing(true);
      // fetch(url, {
      //     method: 'POST',
      //     credentials: 'include',
      //     body: JSON.stringify({
      //         destinationAccount: destinationAccount,
      //         txid: txid,
      //         is_direct_to_recipient: is_direct_to_recipient,
      //         creation_time: creation_time,
      //         salt: salt
      //     })
      // }).then(function (response) {
      //     return response.json();
      // }).then(function (json) {
      //     var is_success = json['status'] == 'OK';

      //     if (!is_success) {
      //         _alert(json, _class);
      //     } else {
      //         clear_metadata();
      //         set_metadata();
      //         success_callback(txid);
      //     }
      // });
    }
  };

  const getLocalAccount = () => {
    const tmp = localStorage.getItem("X5Account");
    if (!tmp) return false;
    else {
      try {
        const account = JSON.parse(tmp);
        return account;
      } catch (e) {
        return false;
      }
    }
  };

  const sendTX = async (data, price, toAddress) => {
    // let gas = await etherscan.proxy.eth_gasPrice();
    // gas = web3.utils.hexToNumber(gas.result);
    // console.log("current gas ", gas, gas + (gas * 0.1));
    // gas = Math.round(gas + (gas * 0.1));
    // // gas = parseInt(web3.utils.fromWei(gas.toString(), 'gwei'))
    // console.log(gas);
    // console.log('====================================');
    // gas = web3.utils.toHex(gas)
    // getGas().then( async (gas) => {
    console.log("sendTX", data, price, toAddress, gasPrice, gasPrice * 1e8);
    const lAccount = getLocalAccount();
    if (!lAccount) {
      console.info("no local account");
      return false;
    }
    const pk = lAccount.privateKey; // x5t wallet private key
    // const txCount = await web3.eth.getTransactionCount(lAccount.address);
    // Build the transaction
    const txObject = {
      from: lAccount.address,
      // nonce: web3.utils.toHex(txCount+1),
      to: toAddress,
      value: web3.utils.toHex(price),
      gasLimit: web3.utils.toHex(500000),
      gasPrice: web3.utils.toHex(gasPrice * 1e8),
      data: data.encodeABI(),
    };
    // Sign the transaction
    console.log("TX", txObject);

    const tx = new Tx(txObject);
    const privateKey1 = Buffer.from(pk.slice(2), "hex");
    tx.sign(privateKey1);

    const serializedTx = tx.serialize();
    const raw = "0x" + serializedTx.toString("hex");

    // Broadcast the transaction
    return await web3.eth.sendSignedTransaction(raw);
    // })
  };

  const buyX5T = async (amount, tokens) => {
    console.log("ethBalance", ethBalance, account);
    if (ethBalance < amount) {
      alert("Sorry you dont have enough ETH, Get more?");
      return false;
    }
    web3.eth.defaultAccount = account;

    setProcessing(true);
    // https://uniswap.org/docs/v1/smart-contracts/exchange/
    console.log(
      "x5Exchange",
      x5t,
      [
        "C02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        "579b5201f1fd40a2084f162A51339126FB0E88c1",
      ],
      account,
      Date.now() + 7 * 60
    );
    const x5tNeeded = x5t - balance;
    const ethNeeded = 0;
    const { _reserve1, _reserve0 } = await x5Exchange.methods
      .getReserves()
      .call();

   await x5tRouter.methods
      .getAmountIn(x5tNeeded, _reserve1, _reserve0)
      .call();
    // ethNeeded = await x5tRouter.methods.getAmountsOut("0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f", _reserve0, _reserve1).call();

    console.log(
      "ethNeeded  x5tNeeded gasPrice",
      ethNeeded,
      x5tNeeded,
      gasPrice,
      web3.utils.toHex(5000000),
      account
    );

    // x5Exchange.methods.TokenPurchase(account, max_tokens, deadline).send({ value: ethValue })
    // x5Exchange.ethToTokenSwapInput(amount, tokens, 40).send({ value: amount })
    const localAccount = getLocalAccount();

    if (localAccount) {
      const data = x5tRouter.methods.swapExactETHForTokens(
        x5tNeeded,
        [
          "C02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          "579b5201f1fd40a2084f162A51339126FB0E88c1",
        ],
        account,
        Date.now() + 7 * 60
      );
      // web3.eth.estimateGas({
      //     to: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      //     data: data.encodeABI()
      // }).then(console.log);

      sendTX(
        data,
        ethNeeded.toString(),
        "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
      ).then((transaction) => {
        console.log("transaction", transaction);

        setProcessing(false);
      });
    } else {
      x5tRouter.methods
        .swapExactETHForTokens(
          x5tNeeded,
          [
            "C02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            "579b5201f1fd40a2084f162A51339126FB0E88c1",
          ],
          account,
          Date.now() + 7 * 60
        )
        .send({
          from: account,
          value: ethNeeded.toString(),
          gasPrice: web3.utils.toHex(gasPrice * 1e8),
          gasLimit: web3.utils.toHex(200000),
        })
        .on("transactionHash", function (hash) {
          console.log("transactionHash", hash);
        })
        .on("receipt", function (receipt) {
          // receipt example
          console.log(receipt);
          setProcessing(false);
        })
        .on("error", console.error); // If there's an out of gas error the second parameter is the receipt.
    }

    // https://uniswap.org/docs/v1/frontend-integration/connect-to-uniswap/
  };

  const setChecked = (e, i) => {
    console.log(i);

    setShipping(parseFloat(shippingOptions[i].rate));
    // setExtra(parseFloat(shippingOptions[i].rate) / 5)
  };

  // const applyCoupon = () => {
  //   setLoading(true);
  //   console.log("applyCoupon", coupon);
  //   const orderData = {
  //     items: [],
  //     coupon,
  //   };
  //   cart.map((item, i) => {
  //     const tmp = {
  //       quantity: item.quantity,
  //       variant_id: item.currentVariant.id,
  //       price: item.currentVariant.price,
  //     };
  //     if (item.quantity) orderData.items.push(tmp);
  //   });
  //   postData("/orders/coupon", orderData)
  //     .then((res) => {
  //       console.log("coupon applied ", res);
  //       if (res.subTotal) {
  //         setTotal(res.subTotal);
  //         setSaved(res.amountSaved);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((res) => {
  //       setLoading(false);
  //       console.error("order error ", res);
  //     });
  // };

  return (
    <Accordion activeKey={currentTab}>
      <Card>
        <Row className="mb-2 m-4">
          <Col>
            <Button
              variant="primary"
              size="lg"
              block
              onClick={() => history.push("/swag")}
            >
              Continue Shopping
            </Button>{" "}
          </Col>
          <Col>
            <Button
              variant={!!currentTab ? "secondary" : "primary"}
              size="lg"
              block
              onClick={() => setTab("0")}
            >
              Open Shopping Cart
            </Button>
          </Col>
        </Row>
        <Accordion.Toggle
          onClick={() => setTab("0")}
          as={Card.Header}
          eventKey="0"
        >
          Order Overview {cart && cart.length ? "(" + cart.length + ")" : null}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <h5>
              <span className="glyphicon glyphicon-shopping-cart"></span>
              Shopping Cart
            </h5>
            <ListGroup>
              {cart && cart.length ? (
                cart
                  .filter((item) => item)
                  .map((product, i) => (
                    <ListGroup.Item key={i}>
                      <Row>
                        <Col xs={6} md={4}>
                          <Image
                            fluid
                            src={
                              product.currentVariant &&
                              product.currentVariant.image
                            }
                            alt="product"
                            rounded
                          />
                        </Col>
                        <Col xs={6} md={4}>
                          <Card.Title as="h5">
                            {product.selectedProduct &&
                              product.selectedProduct.model}
                          </Card.Title>
                          <Card.Text>
                            {product.selectedProduct &&
                              product.selectedProduct.description}
                          </Card.Text>
                        </Col>
                        <Col xs={6} md={4}>
                          <div className="col-md-6 text-left">
                            <h6>
                              <strong>
                                {product.currentVariant.price}{" "}
                                <span className="text-muted">x</span>
                              </strong>
                            </h6>
                          </div>

                          <div className="col-md-4">
                            <input
                              type="number"
                              min="1"
                              step="1"
                              onChange={(e) =>
                                changeQuantity(i, e.target.value)
                              }
                              className="form-control input-sm"
                              value={product.quantity}
                            />
                          </div>
                          <div className="col-md-2">
                            <button
                              onClick={(e) => removeProduct(i)}
                              type="button"
                              className="btn btn-link btn-xs"
                            >
                              <i className="fas fa-trash-alt"></i> Remove
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))
              ) : (
                <h4>Oops! Empty Cart :(</h4>
              )}
              <ListGroup.Item>
                <ListGroup horizontal>
                  <ListGroup.Item>
                    <Form.Control
                      placeholder="Coupon Code"
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon}
                    />
                  </ListGroup.Item>

                  <ListGroup.Item>

                    {/* <Button
                      variant="outline-success"
                      size="lg"
                      block
                      onClick={applyCoupon}
                      disabled={!coupon || loading}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      Apply Coupon
                    </Button> */}

                    
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h5 className="text-right">
                      Amount Saved <strong>${toFixed(saved)}</strong>
                    </h5>
                    <h4 className="text-right">
                      Total <strong>${toFixed(total)}</strong>
                    </h4>
                    <i>Before Shipping</i>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Button
                      variant="outline-success"
                      size="lg"
                      disabled={loading}
                      block
                      onClick={() => (cart && cart.length ? setTab("1") : null)}
                    >
                      Next
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          Shipping
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div className="form-group">
              <div className="col-md-12">
                <h4>Shipping Address</h4>
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-6 col-xs-12">
                <strong>First Name:</strong>
                <input
                  type="text"
                  onChange={(e) => updateAddress("first_name", e.target.value)}
                  name="first_name"
                  className="form-control"
                  value={address.first_name || ""}
                />
              </div>

              <div className="span1"></div>
              <div className="col-md-6 col-xs-12">
                <strong>Last Name:</strong>
                <input
                  type="text"
                  onChange={(e) => updateAddress("last_name", e.target.value)}
                  name="last_name"
                  className="form-control"
                  value={address.last_name || ""}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-12">
                <strong>Address:</strong>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  onChange={(e) => updateAddress("address", e.target.value)}
                  name="address"
                  className="form-control"
                  value={address.address || ""}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-6 col-xs-12">
                <strong>City:</strong>
                <input
                  type="text"
                  onChange={(e) => updateAddress("city", e.target.value)}
                  name="city"
                  className="form-control"
                  value={address.city || ""}
                />
              </div>

              <div className="form-group">
                <div className="col-md-6 col-xs-12">
                  <strong>Country: {address.country} </strong>
                  <select
                    className="form-control"
                    onChange={(e) => updateAddress("country", e.target.value)}
                    name="country"
                    value={address.country || ""}
                  >
                    {countries.map((country, i) => (
                      <option key={i} value={country.value}>
                        {" "}
                        {country.label}{" "}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="span1"></div>
                <div className="col-md-6 col-xs-12">
                  <strong>Zip / Postal Code:</strong>
                  <input
                    type="text"
                    onChange={(e) => updateAddress("zip_code", e.target.value)}
                    name="zip_code"
                    className="form-control"
                    value={address.zip_code || ""}
                  />
                </div>

                <div className="span1"></div>
                <div className="col-md-6 col-xs-12">
                  <strong>State/Province:</strong>
                  <input
                    type="text"
                    onChange={(e) => updateAddress("state", e.target.value)}
                    name="state"
                    className="form-control"
                    value={address.state || ""}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-12">
                <strong>Phone Number:</strong>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  onChange={(e) =>
                    updateAddress("phone_number", e.target.value)
                  }
                  name="phone_number"
                  className="form-control"
                  value={address.phone_number || ""}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-12">
                <strong>Email Address:</strong>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  onChange={(e) =>
                    updateAddress("email_address", e.target.value)
                  }
                  name="email_address"
                  className="form-control"
                  value={address.email_address || ""}
                />
              </div>
            </div>

            <ListGroup horizontal>
              <ListGroup.Item>
                {shippingOptions && shippingOptions.length ? (
                  <Form.Group>
                    {shippingOptions.map((option, i) => (
                      <Form.Check
                        name="terms"
                        type="radio"
                        checked={
                          parseFloat(shippingOptions[i].rate) === shipping
                        }
                        label={
                          option.name +
                          " $" +
                          toFixed(parseFloat(shippingOptions[i].rate), 2)
                        }
                        onChange={(e) => setChecked(e, i)}
                        id={i}
                        key={i}
                      />
                    ))}
                  </Form.Group>
                ) : null}
              </ListGroup.Item>
              <ListGroup.Item>
                <h4 className="text-right">
                  SubTotal <strong>${toFixed(total)}</strong>
                </h4>
                <h4 className="text-right">
                  Shipping Cost <strong>${toFixed(shipping) || "?"}</strong>
                </h4>
                <h4 className="text-right">
                  Total <strong>${toFixed(total + shipping)}</strong>
                </h4>
              </ListGroup.Item>
              <ListGroup.Item>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(xx) => (
                    <Tooltip id="button-tooltip" {...xx}>
                      {!shipping
                        ? "Click Get Shipping Options first, to continue!"
                        : "Go To Checkout"}
                    </Tooltip>
                  )}
                >
                  <Button
                    variant="outline-success"
                    size="lg"
                    block
                    onClick={() =>
                      !shipping
                        ? getShippingOptions()
                        : cart && cart.length
                        ? setTab("2")
                        : null
                    }
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    {!shipping ? "Get Shipping Options" : "Next"}
                  </Button>
                </OverlayTrigger>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          Crypto Payment
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            {!allDone && (
              <h4 className="text-center">
                Total in USD <strong>${toFixed(total + shipping, 2)} </strong> ={" "}
                {toFixed((total + shipping) / ethRate, 3)} ETH = {x5t} X5T
              </h4>
            )}
            {!allDone && (
              <h4 className="text-center">
                Current ETH/USD exchange rate, ${ethRate} USD = 1 ETH{" "}
              </h4>
            )}
            {!allDone && (
              <h4 className="text-center">
                <strong>
                  {x5t > balance ? (
                    <Alert className="text-center" variant={"info"}>
                      You need {x5t - balance} X5T more to complete your swag
                      purchase!
                    </Alert>
                  ) : null}
                  {x5t > balance ? (
                    <h6 className="text-center">
                      <Button
                        variant="outline-info"
                        onClick={(e) =>
                          buyX5T(
                            toFixed((total + shipping) / ethRate, 3),
                            x5t - balance
                          )
                        }
                      >
                        Get X5T with ETH
                      </Button>{" "}
                      {/* {x5t < balance && ethBalance > balance ? <Alert className="text-center" variant={"info"}>
                                    You need ETH to buy X5T, you can get some using the below button!
                                </Alert> : null} */}
                      {/* Or */}{" "}
                      {/* <Button variant="outline-info" onClick={(e) => openUniswap(x5t - balance)}>
                                    Get X5T with other Crypto
                                </Button> */}
                    </h6>
                  ) : null}
                  {x5t > balance ? (
                    <h6 className="text-center">
                      <Button
                        variant="outline-light"
                        size="sm"
                        target="_blank"
                        href={
                          "https://pay.sendwyre.com/?dest=ethereum:" +
                          account +
                          "&destCurrency=ETH&sourceAmount=" +
                          40 +
                          "&accountId=AC-7AG3W4XH4N2&paymentMethod=debit-card"
                        }
                        // onClick={(e) => openWyre(toFixed((total + shipping) * 1.02, 2))}
                      >
                        No Crypto? Get ETH and other crytpo currencies with a
                        debit card.
                      </Button>
                    </h6>
                  ) : null}
                </strong>
              </h4>
            )}
            {!allDone && showUniswap && (
              <Button variant="light" disabled style={{ marginRight: 20 }}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Swaping X5 Tokens Now....
              </Button>
            )}
            {!processing ? (
              <Button
                hidden={!!allDone}
                variant="success"
                onClick={(e) => payCrypto()}
                disabled={
                  Math.round(
                    toFixed((total + shipping) / ethRate, 3) / 0.0002
                  ) > balance
                }
              >
                Place Order Now
              </Button>
            ) : (
              <Button
                variant="primary"
                disabled
                style={{ display: allDone ? "none" : "block" }}
              >
                <Spinner
                  as="span"
                  variant="light"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Processing Payment...
              </Button>
            )}
            {allDone && (
              <SweetInfo variant="success" className="p-5 m-5">
                Your Order was succefully processed and is getting fullfiled,
                check out your email soon for shipping information.
                <br />
                <br />
                <Button
                  variant="success"
                  className="m-4"
                  href={"https://etherscan.io/tx/" + allDone}
                  target="_blank"
                >
                  Order ID: {allDone}
                </Button>
                <br />
                <br />
                This is both your blcockchain transaction and your order ID for
                later reference, you can find it on your profile of recent
                purchases.
              </SweetInfo>
            )}{" "}
          </Card.Body>
        </Accordion.Collapse>
        {error && <Alert variant={"warning"}>{error}</Alert>}{" "}
        {!account && (
          <Alert variant={"warning"}>
            You must <Alert.Link onClick={showLogin}>Login</Alert.Link> your
            crypto wallet! e.g: MetaMask
          </Alert>
        )}
      </Card>
    </Accordion>
  );
}

export default withRouter(Cart);

// {
// !!account && Math.round(toFixed((total + shipping), 3) / 0.05) > balance && <BalancesModal
//     show={modalShow}
//     account={account}
//     ethBalance={ethBalance}
//     openUniswap={openUniswap}
//     amountToSwap={Math.round(toFixed((total + shipping), 3) / 0.05) - balance}
//     onHide={() => setModalShow(false)}
// />
// }
//  function BalancesModal(props) {
//     const [daiB, setDAI] = React.useState(0);
//     const [usdcB, setUSDC] = React.useState(0);
//     const [linkB, setLINK] = React.useState(0);
//     //

//     useEffect(w => {
//         const getBalance = async () => {
//             setLINK(await getBalanceOf("chainlink", props.account))
//             // setUSDC(await getBalanceOf("usdc", props.account))
//             setDAI(await getBalanceOf("dai", props.account))
//         }
//         getBalance()
//     }, []);

//     return (
//         <Modal
//             {...props}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >
//             <Modal.Header closeButton>
//                 <Modal.Title id="contained-modal-title-vcenter">
//                     Accepted Currencies
//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <h4>Choose the currency you want to use to buy X5T</h4>
//                 <ButtonGroup vertical>
//                     <Button variant={props.ethBalance ? "outline-light" : "outline-danger"} style={{marginBottom:10}}>Buy with ETH (Balance:{props.ethBalance})</Button>{' '}
//                     <Button variant="outline-light" >Buy with DAI (Balance:{daiB})</Button>{' '}
//                     <Button variant="outline-light" >Buy with USDC (Balance:{usdcB})</Button>{' '}
//                     <Button variant="outline-light" >Buy with CHAINLINK (Balance:{linkB})</Button>{' '}
//                 </ButtonGroup>
//                 <h6 className="text-center">
//                     <Button variant="outline-info" onClick={(e) => props.openUniswap(props.amountToSwap)}>
//                         Get {props.amountToSwap} more X5T
//                     </Button>
//                 </h6>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button onClick={props.onHide}>Close</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// }

// https://github.com/ccxt/ccxt

/*

1. Get price of x5t from uniswap

2. Test cart payment/order
3. Add new badges!
    3.1 get rid of none deployed badge from home
4. Buy Badge with X5T???
5. deploy

*/
