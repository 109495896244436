import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
import {
    Carousel
} from "react-bootstrap";

function Hero3() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/banners/Swag0x-banner-skilllevel3-1200x200.png"
                    alt="The NFT Print Store"
                />
                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/banners/Swag0x-banner-lwsebps-1200x200.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/images/banners/Swag0xBanner-mug1.png"
                    alt="The NFT Print Store"
                />

                <Carousel.Caption>
                    <h3>+</h3>
                    <p></p>
                </Carousel.Caption>
            </Carousel.Item>
 


        </Carousel>
    );
}

export default Hero3