import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
} from "react-bootstrap";

// import Web3 from "web3";
import StreamDetails from "./StreamDetails.js"
import sablierABI from "./sablierAbi.json";
import { ethers } from "ethers";

// https://etherscan.io/tx/0x278f4fd8301a8ca0f476d375ab82c68c3fbb21c964c4c104c716b9b4b122349e
// const web3 = new Web3(Web3.givenProvider);

// 0x6B175474E89094C44Da98b954EedeAC495271d0F DAI
// 0x514910771AF9Ca656af840dff83E8264EcF986CA LINK
// RCLE
// X5BadgeToken
const tokens = {
    "0x6B175474E89094C44Da98b954EedeAC495271d0F": "DAI",
    "0x514910771AF9Ca656af840dff83E8264EcF986CA": "LINK",
    "0x5A844590c5b8f40ae56190771d06c60b9ab1Da1C": "RCLE",
    "0xyyyyyy": "X5BadgeToken",
}
const signer = ethers.getDefaultProvider();

const getStream = async (x, id) => {
    const sablier = new ethers.Contract(x, sablierABI, signer);
    const streamId = id;
    const stream = await sablier.getStream(streamId);
    return stream;
};

// const denomFactor = Math.pow(10, 18);

const nowEpoch = parseInt(Date.now() / 1000);

const showStream = ({
    amount,
    // startEpo,
    // endEpo,
    // amountLeft,
    rate,
    currency
}) => {

    return <div>
        <h2>Relay Stream Total:  {shop5digits(amount / 10 ** 18)} {currency}</h2>
        <h3>Transfer rate: {shop5digits(rate / 10 ** 18)} {currency}&nbsp;per sec. </h3>
    </div>
};

const shop5digits = (x, y) => parseFloat(x.toFixed(y ? y : 5))

// const updateAmount = (amount, rate, cb) => setInterval(() => {
//     console.log("updateAmount", amount, rate);
//     cb(amount - (rate * 5))
//     updateAmount(amount - (rate * 5), rate, cb)
// }, 5000)



export default function Stream() {
    const [stream, setStream] = useState({
        amount: 0,
        startEpo: "",
        endEpo: "",
        amountLeft: 0,
        rate: 0
    });
    // const [amountLeft, setAmountLeft] = useState(0);
    // const [amountLeft, setAmountLeft] = useState(0);

    useEffect(w => {
        const stream = getStream("0xA4fc358455Febe425536fd1878bE67FfDBDEC59a", 459);
        stream.then(x => {
            if (!x) return;
            console.log("got stream", x, parseInt(x[2]._hex, 16), "value");
            console.log("start epoch", parseInt(x[4]._hex, 16));
            console.log(
                "days gone",
                (nowEpoch - parseInt(x[4]._hex, 16)) / (3600 * 24)
            );
            console.log(
                "money rate per sec",
                parseInt(x[2]._hex, 16) /
                (parseInt(x[5]._hex, 16) - parseInt(x[4]._hex, 16)),
                parseInt(x[7]._hex, 16)
            );
            console.log("end epoch", parseInt(x[5]._hex, 16));

            const amount = parseInt(x[2]._hex, 16);
            const streamFinished = nowEpoch > parseInt(x.stopTime._hex, 16)
            const timeElapsed = nowEpoch - parseInt(x.startTime._hex, 16);
            const moneyStreamed = streamFinished ? amount : timeElapsed * parseInt(x.ratePerSecond._hex, 16);

            const moneyAvailableToWithdraw = nowEpoch > parseInt(x.stopTime._hex, 16) ? moneyStreamed - (parseInt(x.deposit._hex, 16) - parseInt(x.remainingBalance._hex, 16)) : 0
            console.log("amount to withdraw", nowEpoch, parseInt(x.stopTime._hex, 16), timeElapsed, shop5digits(moneyStreamed), shop5digits(moneyAvailableToWithdraw / (10 ** 8)));

            setStream({
                amount,
                startEpo: parseInt(x[4]._hex, 16),
                endEpo: parseInt(x[5]._hex, 16),
                amountStreamed: moneyStreamed,
                streamFinished,
                remaining: parseInt(x.remainingBalance._hex, 16),
                amountLeftToWithdraw: moneyAvailableToWithdraw,
                rate: parseInt(x[7]._hex, 16),
                deposit: parseInt(x[6]._hex, 16),
                token: x.tokenAddress,
                currency: tokens[x.tokenAddress]
            });

            // setAmountLeft(parseInt(x.remainingBalance._hex, 16))

            // setInterval(()=>{
            //   let amount = amountLeft - parseInt(x[7]._hex, 16)
            //   console.log("setInterval",amount);
            //   setAmountLeft( amount )
            // }, 1000)
        });
    }, []);

    return (<Row style={{ marginTop: 40, marginBottom: 40 }}>
        <h1>Welcome to Web3vm X5 Badges Digital Assets</h1>
        <h2>Reserve you badge for free!</h2>
        <Col>
            {showStream(stream)}
            {stream && stream.rate && <StreamDetails stream={stream} />}
        </Col>
    </Row>);
}