
import React from 'react';
// import React, { useState, useEffect } from 'react';

import OneCard from "./OneCard.js";
import OneCard1 from "./OneCard1.js";
import OneCard2 from "./OneCard2.js";
import OneCard3 from "./OneCard3.js";
import OneCard4 from "./OneCard4.js";
import OneCard5 from "./OneCard5.js";
import OneCard6 from "./OneCard6.js";

import {
  Row,
  Col,
  Button
} from "react-bootstrap";
import { 
  Link
} from "react-router-dom";


import Hero from "./heros/Hero.js"
import Hero0 from "./heros/Hero0.js"
import Hero1 from "./heros/Hero1.js"
import Hero2 from "./heros/Hero2.js"
import Hero3 from "./heros/Hero3.js"
import Hero4 from "./heros/Hero4.js"
import Hero5 from "./heros/Hero5.js"
import Hero6 from "./heros/Hero6.js"
import Hero7 from "./heros/Hero7.js"

import HeroAnimation1 from "./heros/HeroAnimation1.js"
import HeroAnimation2 from "./heros/HeroAnimation2.js"

import dataAllTeams from "./components/dataAllTeams.json"


import dataBadgeLevel1 from "./components/dataBadgeLevel1.json"
import dataBadgeLevel2 from "./components/dataBadgeLevel2.json"
import dataBadgeLevel3 from "./components/dataBadgeLevel3.json"
import dataBadgeLevel4 from "./components/dataBadgeLevel4.json"
import dataBadgeLevel5 from "./components/dataBadgeLevel5.json"
import dataBadgeLevel6 from "./components/dataBadgeLevel6.json"

import Footer from './components/Footer'

// import Web3 from "web3";

// const web3 = new Web3(Web3.givenProvider);

// const namedTeams = ["arborland", "hills_overthere", "summerfield", "cactus_flats", "manorland", "sunset_bay", "castaway_bay", "meltenberg", "tall_falls", "cypress_bay", "moon_mountain", "unicorn_spinland", "distant_bigrock", "mountain_valley", "wide_falls", "distant_dunes", "new_promiseland", "windmill_hill", "high_forest", "nights_bridge", "windy_hills", "hills_overhere", "old_promiseland", "yester_year"]

// const x5Badges = []


// https://ipfs.infura.io/ipfs/QmZGjazBuUUhC6gUTBBrBei3V96hwgy7P6cnus7wrVwqL2

// const denomFactor = Math.pow(10, 18);

export default function Home({ ethRate, ref }) {
  // const [address, setAddress] = useState("");

  return (
    <Row>
      <Col>
        <HeroAnimation1 />

        <HeroAnimation2 ref={ref} />


        <hr></hr>

        

        <Hero />

        <Row style={{ marginTop: 40 }}>
          <Col>

          <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content introFjallaOne pt-5">
                            
                            <div id="magic1"></div>

                                <div className="reallybigtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Web3 team and get the badge for free. Great for team building and special events!</div>

                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
          
          </Col>
        </Row>



        <Row className=' mx-lg-5 px-lg-5' style={{ marginTop: 40, marginBottom: 40 }}>
          {dataAllTeams.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={12} key={i} >
                {false && x.tokenId}
                <OneCard
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  price={1}
                  slug={x.name}
                  image={x.image}
                />
              </Col>
            );
          })}
        </Row>


        <Hero0 />

        <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content introFjallaOne pt-5 pb-5 mb-10">

                            <div className="bigtextyellow mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">The next step is to select one Skill Level badge. If you are starting a new account the level one badge will be sent for free. </div>


                                <div className="mdtextwhite mb-3 mt-5" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill level badges are a way to learn new Web3 legal skills. Some skills are easy and inexpensive, some are dificult are priced higher as the skill level increases.  There are six skill levels.</div>

                                <div className="smtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">You can earn Skill Level Badges or buy them with cryptocurrency.  Once you get a Skill Level Badge it is saved forever on the blockchain. </div>

                                <div className="xsmtextdesc mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s"><emwhite>The Skill Level Badge will be connected to your Web3 account so start at the lower levels because each skill level sets you up for the next level. If you are unsure which Skill Level Badge you should start with the <emyellow>Newb Skill Level Badge</emyellow>. It's the green teddy bear and the first one listed.</emwhite> </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>

        <Hero1 />



        <div className="container">
                    <div id="magic2"></div>
                <div className="banner-wrapper">
                    <div className="row align-items-center">



                        <div className="col-lg-12 col-md-12">
                        <div className="banner-content introFjallaOne pt-5 pb-5 mb-5">

                                <div className="bigtextorange mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill Level 1 - Receive a Common Digital Badge</div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Level 1 Skill category. Each Skill Badge has six (6) levels in total. This is the first level. </div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">This training focuses on navigating Web3 technology, not accreditation. Each skill level can either be purchased or earned through a series of tasks and projects. If a user participates and achieves level 6, they will be granted a free membership application to Web3 Legal Engineering. </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>

        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {dataBadgeLevel1.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={4} key={i}>
                {false && x.tokenId}
                <OneCard1
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  slug={x.name}
                  price={10}
                  image={
                    x.image
                  }
                  tokenId={11}
                />
              </Col>
            );
          })}


          <Col md={12}>
            <Button
              className="btn2 btn-gradient-orange mt-5 float-right textFjallaOne mdtextwhite"
              as={Link}
              to="/badges-list/level_1"
              >
              View all Level 1 Badges
            </Button>
          </Col>
          
        </Row>


        <hr></hr>

        <Hero2 />

        <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                        <div className="banner-content introFjallaOne pt-5 pb-5 mb-5">

                                <div className="bigtextorange mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill Level 2 - Receive a Starter Badge</div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Level 2 Skill category. Each Skill Badge has six (6) levels in total. This is the second level. </div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">This training focuses on navigating Web3 technology, not accreditation. Each skill level can either be purchased or earned through a series of tasks and projects. If a user participates and achieves level 6, they will be granted a free membership application to Web3 Legal Engineering. </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>

        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {dataBadgeLevel2.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={4} key={i}>
                <OneCard2
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  price={100}
                  slug={x.name}
                  image={
                    x.image
                  }
                />
              </Col>
            );
          })}


          <Col md={12}>
            <Button
              className="btn2 btn-gradient-orange mt-5 float-right textFjallaOne mdtextwhite"
              as={Link}
              to="/badges-list/level_2"
            >
              View all Level 2 Badges
            </Button>
          </Col>

        </Row>


        <hr></hr>
        
        <Hero3 />

        <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content introFjallaOne pt-5 pb-5 mb-5">

                                <div className="bigtextorange mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill Level 3 - Receive a "Getting There" Badge"</div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Level 3 Skill category. Each Skill Badge has six (6) levels in total. This is the third level. </div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">This training focuses on navigating Web3 technology, not accreditation. Each skill level can either be purchased or earned through a series of tasks and projects. If a user participates and achieves level 6, they will be granted a free membership application to Web3 Legal Engineering.  </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>

        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {dataBadgeLevel3.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={4} key={i}>
                <OneCard3
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  price={200}
                  slug={x.name}
                  image={
                    x.image
                  }
                />
              </Col>
            );
          })}


          <Col md={12}>
            <Button
              className="btn2 btn-gradient-orange mt-5 float-right textFjallaOne mdtextwhite"
              as={Link}
              to="/badges-list/level_3"
            >
              View all Level 3 Badges
            </Button>
          </Col>

        </Row>

        <Hero4 />

        <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content introFjallaOne pt-5 pb-5 mb-5">

                                <div className="bigtextorange mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill Level 4 - Receive an Arrived Badge</div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Level 4 Skill category. Each Skill Badge has six (6) levels in total. This is the fourth level. </div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">This training focuses on navigating Web3 technology, not accreditation. Each skill level can either be purchased or earned through a series of tasks and projects. If a user participates and achieves level 6, they will be granted a free membership application to Web3 Legal Engineering.  </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>
        

        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {dataBadgeLevel4.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={4} key={i}>
                <OneCard4
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  price={500}
                  slug={x.name}
                  image={
                    x.image
                  }
                />
              </Col>
            );
          })}


          <Col md={12}>
            <Button
              className="btn2 btn-gradient-orange mt-5 float-right textFjallaOne mdtextwhite"
              as={Link}
              to="/badges-list/level_4"
            >
              View all Level 4 Badges
            </Button>
          </Col>

        </Row>


        <Hero5 />

        <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content introFjallaOne pt-5 pb-5 mb-5">

                                <div className="bigtextorange mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill Level 5 - Receive a "Made It Badge</div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Level 5 Skill category. Each Skill Badge has six (6) levels in total. This is the fifth level. </div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">This training focuses on navigating Web3 technology, not accreditation. Each skill level can either be purchased or earned through a series of tasks and projects. If a user participates and achieves level 6, they will be granted a free membership application to Web3 Legal Engineering.  </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>

        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {dataBadgeLevel5.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={4} key={i}>
                <OneCard5
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  price={1000}
                  slug={x.name}
                  image={
                    x.image
                  }
                />
              </Col>
            );
          })}


          <Col md={12}>
            <Button
              className="btn2 btn-gradient-orange mt-5 float-right textFjallaOne mdtextwhite"
              as={Link}
              to="/badges-list/level_5"
            >
              View all Level 5 Badges
            </Button>
          </Col>

        </Row>


        <hr></hr>
        
        <Hero6 />

        <div className="container">
                <div className="banner-wrapper">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="banner-content introFjallaOne pt-5 pb-5 mb-5">

                                <div className="bigtextorange mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Skill Level 6 - Receive a Master Badge</div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">Select a Level 6 Skill category. Each Skill Badge has six (6) levels in total. This is the sixth and final level. </div>

                                <div className="mdtextwhite mb-3" data-animation="fadeInUpShorter" data-animation-delay="1.5s">This training focuses on navigating Web3 technology, not accreditation. Each skill level can either be purchased or earned through a series of tasks and projects. If a user participates and achieves level 6, they will be granted a free membership application to Web3 Legal Engineering. </div>


                                <hr></hr>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <hr></hr>


        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {dataBadgeLevel6.map((x, i) => {
            return (
              <Col lg={2} md={3} sm={3} xs={4} key={i}>
                <OneCard6
                  type={x.type}
                  title={x.name.split("_").join(' ').toUpperCase()}
                  price={2000}
                  slug={x.name}
                  image={
                    x.image
                  }
                />
              </Col>
            );
          })}


          <Col md={12}>
            <Button
              className="btn2 btn-gradient-orange mt-5 float-right textFjallaOne mdtextwhite mb-10"
              as={Link}
              to="/badges-list/level_6"
            >
              View all Level 6 Badges
            </Button>
          </Col>

        </Row>

        <Hero7 />


        <Footer />


        <hr></hr>
        
        <section id="contact" className="map">
          <iframe title="contactFrame" width="100%" height="600" frameBorder="0" style={{ border: 0}} src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13296.22943987236!2d-117.73306406989742!3d33.577859932905646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1561198131994!5m2!1sen!2sus"
            allowfullscreen></iframe>
        </section>
      </Col>
    </Row>)
}