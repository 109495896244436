import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./styles.css";
// import Web3 from 'web3';
import Iframe from "react-iframe";
// import Intercom from 'react-intercom';

import {
  Container,
  // Row,
  // Col,
  Image,
  Spinner,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import Home from "./Home.js";
import Badges from "./Badges.js";
import Stream from "./Stream.js";
import BadgesList from "./BadgesList.js";
import Badge from "./Badge.js";
import Profile from "./Profile.js";
import Cart from "./Cart.js";
import getWeb3 from "./getWeb3.js";
// import EnsResolver from "ens-resolver";
import Web3 from "web3";
import SuperSlider from "super-react-slider";
// import { OpenSeaPort, Network } from "opensea-js";
// import x5tAirDrop from "./abis/x5tAirDrop.js"
// import Particles from 'react-particles-js';
import Identicon from "identicon.js";
import makeBlockie from "ethereum-blockies-base64";
// import { useHistory } from "react-router-dom";

// import Hero from "./heros/Hero.js"

// console.log("super-react-slider", SuperSlider);

const ethers = require("ethers");
const cc = require("cryptocompare");
cc.setApiKey(
  "5eb9da642a6f2d6b932710aca0bd8fdba1ceaf1a1376a0fac63d05b86877635e"
);

// https://unilogin.io/

// const web3 = new Web3(Web3.givenProvider);

// import Unilogin from '@universal-login/provider'

// UniLogin.setupPicker(web3)

// web3.setprovider(UniLoginProvider.createPicker(web3.surrentProvider))

// import faker from "faker";
// import sablierABI from "./sablierAbi.json";
// import { ethers } from "ethers";
// import ActivateConnectors from "./ActivateConnectors.js";
// import StreamDetails from "./StreamDetails";

// const ccxt = require('ccxt');

// const namedTeams = [
//   "arborland",
//   "hills_overthere",
//   "summerfield",
//   "cactus_flats",
//   "manorland",
//   "sunset_bay",
//   "castaway_bay",
//   "meltenberg",
//   "tall_falls",
//   "cypress_bay",
//   "moon_mountain",
//   "unicorn_spinland",
//   "distant_bigrock",
//   "mountain_valley",
//   "wide_falls",
//   "distant_dunes",
//   "new_promiseland",
//   "windmill_hill",
//   "high_forest",
//   "nights_bridge",
//   "windy_hills",
//   "hills_overhere",
//   "old_promiseland",
//   "yester_year",
// ];

// for ethersjs
// ul = ULIframeProvider.createPicker(window.ethereum);
// web3 = new Web3(ul);

// for web3js
// ul = ULIframeProvider.createPicker(window.ethereum);
// web3 = new Web3(ul);

// const getContract = (x) => {
//   console.log("contract", x);
//   return new web3.eth.Contract(x.abi, x.address);
// };

const getX5TContract = async (web3) => {
  console.log("getX5TContract", web3);

  var x5tokenContract = new web3.eth.Contract(
    [
      {
        constant: true,
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        name: "transfer",
        outputs: [{ type: "bool", name: "out" }],
        inputs: [
          { type: "address", name: "_to" },
          { type: "uint256", name: "_value" },
        ],
        constant: false,
        payable: false,
        type: "function",
        gas: 75034,
      },
    ],
    "0x579b5201f1fd40a2084f162a51339126fb0e88c1"
  );

  // var tokenInst = x5tokenContract.at("0x579b5201f1fd40a2084f162a51339126fb0e88c1");
  return x5tokenContract;
};

const getX5BContract = async (web3) => {
  console.log("getX5BContract", web3);

  var x5bContract = new web3.eth.Contract(
    [
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    "0x743e27e367419d3f50855894889da135a716464e"
  );

  return x5bContract;
};

// https://etherscan.io/tx/0x278f4fd8301a8ca0f476d375ab82c68c3fbb21c964c4c104c716b9b4b122349e
// const infuraId = "ec9c99d75b834bac8dd4bfacad8cfdf7"
// var INFURA_TOKEN = "d9e96b40071b4f79b975d2cd638a4f1f"

const provider = new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82"
);
const web3 = new Web3(Web3.givenProvider || provider);

// const seaport = new OpenSeaPort(provider, {
//   networkName: Network.Main,
// });

// const nowEpoch = parseInt(Date.now() / 1000);

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export default function App() {
  const [account, setAccount] = useState("");
  const [showUniswap, setShowUniswap] = React.useState(false);
  const [showWyre, setShowWyre] = React.useState(false);
  const [showModal, setShow] = React.useState(false);
  const [airdrop, setAirDrop] = React.useState(false);
  const [subscribed] = React.useState(false);
  // const [subscribed, setSubscribe] = React.useState(false);
  const [modalMsg, setModalMsg] = React.useState("");
  const [ttl, setTTL] = React.useState(0);
  const [amountNeeded, setAmountNeeded] = React.useState(0);
  const [amountNeededUSD, setAmountNeededUSD] = React.useState(0);
  // const [ethRate] = React.useState(0);
  const [ethRate, setRate] = React.useState(0);

  const [balance, setBalance] = React.useState(0);
  const [x5b, setX5B] = React.useState(0);
  // const [gasPrice] = React.useState(0);
  const [gasPrice, setGasPrice] = React.useState(0);
  const [ethBalance, setEthBalance] = React.useState(0);
  const [x5tokenContract, setX5tokenContract] = React.useState({});
  const myRef = useRef(null);
  // const executeScroll = () => scrollToRef(myRef);
  // const history = useHistory();

  // const getPrices = async () => {
  //   // let kraken = new ccxt.kraken()
  //   // let bitfinex = new ccxt.bitfinex({ verbose: true })
  //   // let huobipro = new ccxt.huobipro()
  //   // // console.log(kraken.id, await kraken.loadMarkets())
  //   // console.log(bitfinex.id, await bitfinex.loadMarkets())
  //   // console.log(huobipro.id, await huobipro.loadMarkets())
  //   // // https://cors-anywhere.herokuapp.com/
  //   // console.log(bitfinex.id, await bitfinex.fetchTicker('BTC/USD'))
  //   // console.log(huobipro.id, await huobipro.fetchTrades('ETH/USD'))
  // }




  // FIX THIS !!!!!!!!!!!!!!!!
  useEffect(() => {
    // if (subscribed) subscribeEvents();
    // if (!checkCache()) {
    //   setShow(true);
    // } else 
    // {
      // const lx = getLocalAccount();
      // if (lx) updateBalance(lx.address);
    // }


    cc.price("ETH", "USD").then((prices) => {
      console.log(prices);
      setRate(prices.USD);
    });


    checkGasPrice();

    setInterval(() => {
      cc.price("ETH", "USD").then((prices) => {
        console.log(prices);
        // -> { USD: 1100.24 }
        setRate(prices.USD);
        checkGasPrice();

        // const lx = getLocalAccount();
        // if (lx) {
        //   updateBalance(lx.address);
        // } 
        // else {
        //   updateBalance();
        // }
      });


      if (!!account)
        web3.eth.getBalance(account, (err, bal) => {
          if (err) {
            console.log(`getBalance error: ${err}`);
          } else {
            const newBal = parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(
              4
            );
            if (newBal !== ethBalance) setEthBalance(newBal);
          }
        });
      // executeScroll()
    }, 50000);
  }, [account, ethBalance, subscribed]);

  const endpoint = "https://swag0x-com.firebaseapp.com/api";

  async function postData(url = "", data = {}, type = "POST") {
    let options = {
      method: type, // *GET(default), POST, PUT, DELETE
      mode: "cors", // no-cors, *cors, same-origin
      crossDomain: true,
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    };
    if (type !== "GET") options["body"] = JSON.stringify(data);
    const response = await fetch(endpoint + url, options);
    return await response.json(); // parses JSON response into native JavaScript objects
  }

  const checkGasPrice = async () => {
    const res = await fetch("https://ethgasstation.info/json/ethgasAPI.json");
    // res = await res.json();
    console.log("check gas price", res);
    setGasPrice(res.fast);
    return res;
  };

  const airdropAddress = (data) => {
    const e = "/x5tdrop/" + account;
    if (gasPrice > 350) {
      //throw error message to user can't send airdrop gas price too high

      return false;
    }
    const ttlNow = Date.now();
    console.log("ttlNow  .now()", ttlNow);
    setTTL(ttlNow);
    setAirDrop(true);
    postData(e, {}, "GET").then((x) => {
      console.log("airdrop sent", x);
      // history.push("/badges-list/team")
    });
  };

  const tryLogin = (noProvider = false, newAcc = "") => {
    console.log("try to login");
    getWeb3(noProvider).then(async (xweb3) => {
      console.log("web3", web3);

      const acc = noProvider ? newAcc : await web3.eth.getAccounts();
      console.log("current account", acc, newAcc);

      if (acc && acc.length) {
        setAccount(acc[0]);
        dropX5(acc);
        updateBalance(acc[0]);
        localStorage.setItem("X5AccountWeb3", acc[0]);
        // web3.eth.getBalance(acc[0]).then((wei) => {
        //   setEthBalance(parseFloat(web3.utils.fromWei(wei, "ether")).toFixed(4))
        // })
        // const x5T_contract = await getX5TContract(web3)
        // const xbalance = await x5T_contract.methods.balanceOf(acc[0]).call()

        // console.log('x5t balance', xbalance, x5T_contract);
        // setBalance(xbalance)
        // setX5tokenContract(x5T_contract)
      }
    });
  };
  //notes
  /*
  1 pass web 3 address into new member function
  2 get transaction tx hash and verify it was mined
  3 drip eth method
  4 drip erc20
  5 subscribe to events, whatever: addmember, drip ether and drip erc20
*/

  const dropX5 = async (acc) => {
    // const airdrop = getContract(x5tAirDrop)
    // console.log('dropX5 ', acc, airdrop);
    subscribeAirDrop(acc);
    // airdrop.methods.dropX5(acc).call()
    // .then((x) => {
    //   console.log("account", acc, "added");
    // })

    // .on('transactionHash', function (hash) {
    //   console.log("account", hash, "transactionHash");
    // })
    // .on('receipt', function (receipt) {
    //   console.log("account", receipt, "receipt");
    // })
    // .on('confirmation', function (confirmationNumber, receipt) {
    //   console.log("account", confirmationNumber, receipt, "confirmation");
    // })
    // .on('error', console.error);
  };

  // const checkCache = () => {
  //   const tmp = localStorage.getItem("X5Account");
  //   const tmp2 = localStorage.getItem("X5AccountWeb3");
  //   if (!tmp && !tmp2) return false;
  //   else if (tmp2) {
  //     try {
  //       tryLogin();
  //       return true;
  //     } catch (e) {
  //       return false;
  //     }
  //   } else {
  //     try {
  //       const account = JSON.parse(tmp);
  //       setAccount(account.address);
  //       // web3.eth.accounts.privateKeyToAccount(account.privateKey);
  //       web3.eth.accounts.wallet.add(account.privateKey);
  //       return true;
  //     } catch (e) {
  //       return false;
  //     }
  //   }
  // };

  const loadWeb3Modal = () => {
    setShow(false);
    tryLogin();
  };

  const generateWeb3Address = async () => {
    // const newAccount = web3.eth.accounts.create(web3.utils.randomHex(32));
    const mnemonic = await ethers.utils.HDNode.entropyToMnemonic(
      ethers.utils.randomBytes(16)
    );
    const wallet = ethers.Wallet.fromMnemonic(mnemonic);
    console.log("generateWeb3Address", wallet);
    // setShow(false)
    setModalMsg(wallet.mnemonic);
    setAccount(wallet.address);
    const cacheIt = {
      address: wallet.address,
      privateKey: wallet.privateKey,
    };
    localStorage.setItem("X5Account", JSON.stringify(cacheIt));
    // web3.eth.accounts.privateKeyToAccount(wallet.privateKey);
    web3.eth.accounts.wallet.add(wallet.privateKey);
    web3.eth.defaultAccount = wallet.address;
    // tryLogin( true, wallet.address )
    web3.eth.getBalance(wallet.address).then((wei) => {
      console.log("balance", wei);
      setEthBalance(parseFloat(web3.utils.fromWei(wei, "ether")).toFixed(4));
    });
    dropX5(wallet.address);
    const x5T_contract = await getX5TContract(web3);
    const xbalance = await x5T_contract.methods
      .balanceOf(wallet.address)
      .call();

    console.log("x5t balance", xbalance, x5T_contract);
    setBalance(xbalance);
    setX5tokenContract(x5T_contract);
  };

  // function generateAddressesFromSeed(mnemonic, count) {
  //   let bip39 = require("bip39");
  //   let hdkey = require("ethereumjs-wallet/hdkey");
  //   let seed = bip39.mnemonicToSeedSync(mnemonic);
  //   let hdwallet = hdkey.fromMasterSeed(seed);
  //   let wallet_hdpath = "m/44'/60'/0'/0/";

  //   let accounts = [];
  //   for (let i = 0; i < count; i++) {
  //     let wallet = hdwallet.derivePath(wallet_hdpath + i).getWallet();
  //     let address = "0x" + wallet.getAddress().toString("hex");
  //     let privateKey = wallet.getPrivateKey().toString("hex");
  //     accounts.push({ address: address, privateKey: privateKey });
  //   }

  //   return accounts;
  // }

  const closeUniswap = () => {
    setShowUniswap(false);
  };

  // const getLocalAccount = () => {
  //   const tmp = localStorage.getItem("X5Account");
  //   if (!tmp) return false;
  //   else {
  //     try {
  //       const account = JSON.parse(tmp);
  //       return account;
  //     } catch (e) {
  //       return false;
  //     }
  //   }
  // };

  // const getToken = (id) => {
  //   const asset = seaport.api.getAsset({
  //     tokenAddress: "0x743e27e367419d3f50855894889da135a716464e",
  //     tokenId: id, // string | number | null
  //   });
  //   console.log("asset", asset);
  //   return asset;
  // };

  const openUniswap = (number) => {
    setAmountNeeded(number);
    setShowUniswap(true);
  };
  const openWyre = (number) => {
    setAmountNeededUSD(number);
    setShowWyre(true);
  };

  // AirDrop 0xE67D81F542aAbB5A65eC05Ba45a831a63d9ab036
  // const subscribeEvents = () => {
  //   const subscription = "web3.eth"
  //     .subscribe(
  //       "logs",
  //       {
  //         address: "0xE67D81F542aAbB5A65eC05Ba45a831a63d9ab036",
  //         topics: [
  //           "0xcd60aa75dea3072fbc07ae6d7d856b5dc5f4eee88854f5b4abf7b680ef8bc50f",
  //         ],
  //       },
  //       (error, result) => {
  //         console.log("error", error, result);
  //       }
  //     )
  //     .on("data", (log) => {
  //       console.log("data", log);
  //       updateBalance();
  //       closeUniswap();
  //     })
  //     .on("changed", (log) => {
  //       console.log("logs changes", log);
  //     });

  //   // X5Badges 0x743e27e367419d3f50855894889da135a716464e
  //   subscription
  //     .subscribe(
  //       "logs",
  //       {
  //         address: "0x743e27e367419d3f50855894889da135a716464e",
  //         topics: [
  //           "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //         ],
  //       },
  //       function (error, result) {
  //         console.log("error", error, result);
  //       }
  //     )
  //     .on("data", (log) => {
  //       console.log("data", log);
  //       updateBalance();
  //       closeUniswap();
  //     })
  //     .on("changed", (log) => {
  //       console.log("logs changes", log);
  //     });

      
  //   // unsubscribes the subscription
  //   // subscription.unsubscribe(function (error, success) {
  //   //     if (success)
  //   // const filter = web3.eth.filter('latest');
  //   // filter.watch((err, res) => {
  //   //   if (err) {
  //   //     console.log(`Watch error: ${err}`);
  //   //   } else {
  //   //     // Update balance
  //   //     web3.eth.getBalance( account, (err, bal) => {
  //   //       if (err) {
  //   //         console.log(`getBalance error: ${err}`);
  //   //       } else {
  //   //         setEthBalance(parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(4))
  //   //       }
  //   //     });
  //   //   }
  //   // });

  //   // web3.eth.subscribe("newBlockHeaders", (err, result) => {
  //   //   if (err) {
  //   //     console.log(err);
  //   //   } else {
  //   //     web3.eth.getBalance(account ).then(bal => {
  //   //       console.log("user: ", account );
  //   //       console.log("balance: ", bal);
  //   //       setEthBalance(parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(4))
  //   //     });
  //   //   }
  //   // })
  //   setSubscribe(true);
  // };

  // Airdrop X5T
  const subscribeAirDrop = (account) => {
    const subscription = "web3.eth"
      subscription
      .subscribe(
        "logs",
        {
          address: "0xd02e3817c4e3e4fc506a953a1e72e25333cfff8a",
          topics: [
            "0x1799c88637ff4c9e1628a128e950e8861d19b654d8e55679325d60f4ea78c27c",
          ],
        },
        function (error, result) {
          console.log("error", error, result);
        }
      )
      .on("data", function (log) {
        console.log("data", log);
        setAirDrop(false);
        setShow(false);
        updateBalance(account);
      })
      .on("changed", function (log) {
        console.log("logs changes", log);
      });

    // unsubscribes the subscription
    // subscription.unsubscribe(function (error, success) {
    //     if (success)
  };

  const updateBalance = async (xaccount = "") => {
    console.log("updateBalance**************", xaccount);
    const yaccount = await web3.eth.getAccounts();
    if (!xaccount && (!yaccount || !yaccount.length)) return false;

    xaccount = !!xaccount ? xaccount : yaccount[0];
    console.log("update balance", xaccount, yaccount, account);
    web3.eth.getBalance(xaccount).then((wei) => {
      setEthBalance(parseFloat(web3.utils.fromWei(wei, "ether")).toFixed(4));
    });
    const x5T_contract = await getX5TContract(web3);
    const xbalance = await x5T_contract.methods.balanceOf(xaccount).call();

    console.log("x5t balance", xbalance, x5T_contract);
    setBalance(xbalance);
    setX5tokenContract(x5T_contract);

    const x5B_contract = await getX5BContract(web3);
    const x5b_balance = await x5B_contract.methods.balanceOf(xaccount).call();

    console.log("x5b_balance", x5b_balance, x5B_contract);
    setX5B(x5b_balance);
  };

  const kFormatter = (number) => {
    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    // what tier? (determines SI symbol)
    var tier = (Math.log10(number) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  };

  // if (!address)
  //   web3.eth.getAccounts().then(address => {
  //     setAddress(address);
  //   });

  //v2 is the amount sent
  //v4 is epoch start in hex
  //v5 is end date epoch in hex
  //v6 amount left (total minus widthrawn)
  //v7 money rate

  // const deposit = "2999999999999998944000"; // almost 3,000, but not quite
  // const now = Math.round(new Date().getTime() / 1000); // get seconds since unix epoch
  // const startTime = now + 3600; // 1 hour from now
  // const stopTime = now + 2592000 + 3600;

  // 0xd287fb79cd09 mine
  // 0x041ca7e961012f
  // console.log("sablier stream", stream);

  return (
    <Container fluid className="App">
      <Router>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand as={Link} to="/">
            <div className="mb-2 textFjallaOne mdtextorange">The NFT PrintStore</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/swag">
                <div className="mb-2 textFjallaOne smtextwhite">
                  Shop for Swag
                </div>
              </Nav.Link>

              <NavDropdown title="Badges" id="collasible-nav-dropdown">
                <div className="dropdownOne textFjallaOne smtextwhite">
                  Skill Badges
                </div>
                <NavDropdown.Item href="/badges">Team Badges</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Level Badges
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Virtual Reality Storefront"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  target="_blank"
                  href="https://www.cryptovoxels.com/parcels/1311"
                >
                  Visit CryptoVoxel Store
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Create Custom Badge?
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav>
              <Nav.Link href="/cart">
                <div className="mb-2 textFjallaOne smtextorange">
                  Shopping Cart
                </div>
              </Nav.Link>

              <Nav.Link onClick={() => (!account ? getWeb3() : null)}>
                {account ? (
                  <div>
                    <div className="mb-2 textFjallaOne xxsmtextdesc">
                      <span style={{ display: "inline-block" }}>
                        <Link to={"/profile/" + account}>
                          <div className="textFjallaOne smtextwhite">
                            Account: (
                            {account.substr(0, 6) +
                              "...  " +
                              account.substr(38, 4)}
                            )
                          </div>

                          <img
                            className="ml-1 rounded-5 mr-2"
                            width="24"
                            height="24"
                            src={`data:image/png;base64,${new Identicon(
                              account,
                              30
                            ).toString()}`}
                            alt="AcctIconImage"
                          />

                          <img
                            style={{ width: 24, marginRight: 5 }}
                            src={
                              "https://avatars.dicebear.com/v2/gridy/" +
                              account +
                              ".svg"
                            }
                            alt={account}
                            title={account}
                          />

                          <img
                            className="ml-1 rounded-5 mr-2"
                            width="24"
                            height="24"
                            src={makeBlockie(account, 30).toString()}
                            alt="AcctIconImage"
                          />
                        </Link>

                        <emwhite>
                          Current balances: {balance ? kFormatter(balance) : 0}{" "}
                          X5T | {x5b ? kFormatter(x5b) : 0} X5B |{" "}
                          <emyellow>
                            <b>Exchange</b>
                          </emyellow>{" "}
                          {showUniswap ? (
                            <b
                              onClick={() => setShowUniswap(!showUniswap)}
                              style={{ color: "orange" }}
                            >
                              Close Widget
                            </b>
                          ) : (
                            <b onClick={() => setShowUniswap(!showUniswap)}>
                              {ethBalance && ethBalance}
                              <emyellow> ETH</emyellow>
                            </b>
                          )}
                        </emwhite>
                      </span>
                    </div>
                  </div>
                ) : (
                  "Connect Wallet"
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {showUniswap && (
          <Iframe
            url={
              "https://uniswap.exchange/swap?exactField=output?exactAmount=" +
              amountNeeded +
              "?outputCurrency=0x579b5201f1fd40a2084f162a51339126fb0e88c1"
            }
            width="450px"
            height="750px"
            id="myId"
            className="uniswapWidget"
            display="initial"
            position="absolute"
            styles={{
              border: "0",
              margin: "0 auto",
              borderRadius: "10px",
              maxWidth: "600px",
              minWidth: "300px",
              zIndex: 100,
            }}
          />
        )}

        <Modal show={showWyre} onHide={() => setShowWyre(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Ethereum transactions require Ether which is required to process
              public blockchain transactions. You can buy some Ether instantly
              with a debit card or with ApplePay.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Iframe
              url={
                "https://pay.sendwyre.com/?dest=ethereum:" +
                account +
                "&destCurrency=ETH&sourceAmount=" +
                amountNeededUSD +
                "&accountId=AC-7AG3W4XH4N2&paymentMethod=debit-card"
              }
              width="450px"
              height="750px"
              id="myId"
              className="uniswapWidget"
              display="initial"
              position="relative"
              styles={{
                border: "0",
                margin: "0 auto",
                borderRadius: "10px",
                maxWidth: "600px",
                minWidth: "300px",
                zIndex: 100,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowWyre(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <ModalConnect
          show={showModal}
          ttl={ttl}
          airdropping={airdrop}
          gasPriceHigh={gasPrice > 350}
          airdrop={airdropAddress}
          modalMsg={modalMsg}
          loadWeb3Modal={loadWeb3Modal}
          generateWeb3Address={generateWeb3Address}
          closeModal={() => setShow(false)}
          onHide={() => setShow(false)}
        />
        <Switch>
          <Route exact path="/cart">
            <Cart
              gasPrice={gasPrice}
              updateBalance={updateBalance}
              openUniswap={openUniswap}
              openWyre={openWyre}
              showUniswap={showUniswap}
              closeUniswap={closeUniswap}
              balance={balance}
              ethBalance={ethBalance}
              ethRate={ethRate}
              account={account}
              x5tokenContract={x5tokenContract}
              showLogin={() => getWeb3()}
            />
          </Route>
          <Route exact path="/badges">
            <Badges account={account} />
          </Route>
          <Route exact path="/badge/:id">
            <Badge account={account} x5b={x5b} updateBalance={updateBalance} />
          </Route>
          <Route exact path="/teambadge/:id">
            <Badge account={account} x5b={x5b} updateBalance={updateBalance} />
          </Route>
          <Route exact path="/levelbadge/:id">
            <Badge account={account} x5b={x5b} updateBalance={updateBalance} />
          </Route>
          <Route exact path="/profile/:address">
            <Profile account={account} balance={x5b} />
          </Route>
          <Route path="/stream">
            <Stream account={account} />
          </Route>
          <Route exact path="/badges-list/:slug">
            <BadgesList account={account} />
          </Route>
          <Route exact path="/stream/:id">
            <Stream account={account} />
          </Route>
          <Route path="/">
            <Home ref={myRef} ethRate={ethRate} account={account} />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
}

function ModalConnect(props) {
  const [message, setMessage] = React.useState("");
  // const [value, setValue] = React.useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [localBoxValue, setBoxValue] = useState(0);

  useEffect(() => {
    const messages = [
      "Your account is being setup, please be patient",
      "Sometimes transactions get back-up and it takes a little longer than normal.",
      "Ethereum transaction require a little e-fuel to process, we are taking care of that for you!",
      "Almost there, don't forget to check our amazing Swag store :)",
      "Although is takes a little while to process your free account; it's worth it!",
      "You are now part of the Web3 universe, very exciting!",
    ];

    setInterval(() => {
      setMessage(messages[Math.floor(Math.random() * messages.length)]);
    }, 5000);
    console.log("props.ttl", props.ttl);
  }, [props.ttl]);

  // const handleSlide = (e) => {
  //   console.log("Change", e);
  //   // setValue(e)
  // };

  const handleChangeSlider = (val, x) => {
    console.log("change", val, x);
    setSliderValue(val);
    setBoxValue(val * 500);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New User Onboarding and Web3 Login
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>
          Do you have a Web3 address? If you are unsure, you can create a new
          one now.
        </h4>
        <div>
          {!props.modalMsg &&
            "In order to get X5T tokens you need a web3 address."}
          {props.modalMsg && (
            <p>
              This is your private seed phrase for your new Web3 account. Please
              write it down and store it in a very-safe place because this is
              THE ONLY WAY TO RECOVER your account! We do not keep your
              information and have no way to restore your account if you lose
              your seed phrase or private key. This is for your protection
              because only you (or maybe a trustee or attorney) should have full
              control over a Web3 account.":
              <br />
              <b style={{ color: "yellow" }}> {props.modalMsg}</b>
            </p>
          )}
        </div>
        {props.modalMsg && (
          <Button
            disabled={props.airdropping || props.gasPriceHigh}
            onClick={props.airdrop}
          >
            {props.airdropping && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}{" "}
            {!props.airdropping
              ? "Get a starter X5T token for free"
              : "X5T starter token incoming! ... please wait"}
          </Button>
        )}
        <br />
        <br />
        {props.modalMsg && props.gasPriceHigh && (
          <Alert variant="warning">
            <Alert.Heading>
              Sorry the transaction costs are too-high right now to send you a
              free X5T token. The good news is your Web3 address is all set-up
              and you can get your first team badge airdropped to your account
              for FREE right now and begin shopping and collecting skill badges.
              You will need to add some funds to your account and get some X5T
              tokens to pruchase swag. We make that part easy!
            </Alert.Heading>
          </Alert>
        )}
        <br />

        {props.modalMsg && props.airdropping && (
          <SuperSlider
            value={sliderValue}
            min={1}
            max={100}
            ticks={true}
            popoverMarkerOnly={true}
            displayPopover={true}
            // popoverValue={<h5>Epico {sliderValue}</h5>}
            displayBox={true}
            boxValue={localBoxValue + " X5T Tokens Value Swag0x Offers"}
            markers={[
              {
                value: 3,
                label: (
                  <div>
                    <h5>Stickers start at 1,500 X5T</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmXNSp1bhXacffDFqRNVkVWDyShXQ4WKyDMDYKBrYKc6Jw"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 5,
                label: (
                  <div>
                    <h5>Mugs starting at 2,500 X5T</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTqawDCgTy9bexgQw3jAZSdhR5Saux5rgJNdqCYc8fv2a"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 10,
                label: (
                  <div>
                    <h5>T-shirts starting at 5,000 X5T</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmWNt9ziwyqn5knyhfWcBkAS3ehG1U6kPo54dPRJrg8Rzv"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 20,
                label: (
                  <div>
                    <h5>Hoodies starting at 10,000 X5T</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmYdtJH2uL5BmL6ja3Zhi3ASL6xURVh4DCsQPNPmCDnEKD"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 30,
                label: (
                  <div>
                    <h5>Backpacks starting at 15,000 X5T</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmQanzLrjdpzozXkt2YbUND9891zYxYFuoySq7dCfADnp1"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 40,
                label: (
                  <div>
                    <h5>20,000 X5T Swag Pack TBD</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTGoTZkzSmFRvDsrL835SjzCd66vRTUFSrvXhacauWffU"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 50,
                label: (
                  <div>
                    <h5>25,000 X5T Swag Pack TBD</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTGoTZkzSmFRvDsrL835SjzCd66vRTUFSrvXhacauWffU"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 60,
                label: (
                  <div>
                    <h5>30,000 X5T Swag Pack TBD</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTGoTZkzSmFRvDsrL835SjzCd66vRTUFSrvXhacauWffU"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 70,
                label: (
                  <div>
                    <h5>35,000 X5T Swag Pack TBD</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTGoTZkzSmFRvDsrL835SjzCd66vRTUFSrvXhacauWffU"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 80,
                label: (
                  <div>
                    <h5>40,000 X5T Swag Pack TBD</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTGoTZkzSmFRvDsrL835SjzCd66vRTUFSrvXhacauWffU"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
              {
                value: 90,
                label: (
                  <div>
                    <h5>45,000 X5T Swag Pack TBD</h5>{" "}
                    <Image
                      style={{ width: 50, marginRight: 5 }}
                      src={
                        "https://ipfs.infura.io/ipfs/QmTGoTZkzSmFRvDsrL835SjzCd66vRTUFSrvXhacauWffU"
                      }
                      alt="image"
                    />{" "}
                  </div>
                ),
              },
            ]}
            handleColor={"magenta"}
            onChange={handleChangeSlider}
          />
        )}
        <br />
        {props.modalMsg &&
          !!props.ttl &&
          props.airdropping &&
          (Date.now() - props.ttl) / 1000 > 60 && (
            <h5>
              The X5T token drop process is taking a long-time to process. You
              can close this window and the X5T token will arrive automatically
              in your new Web3 account. Please check back later.
            </h5>
          )}
        {props.modalMsg &&
          !!props.ttl &&
          props.airdropping &&
          (Date.now() - props.ttl) / 1000 > 60 && (
            <Button onClick={props.closeModal}>
              In the meantime browse the team and skill badges in the Swag0x
              store and select some cool merchandise!
            </Button>
          )}
        {props.airdropping && <p style={{ marginTop: 5 }}>{message}</p>}
      </Modal.Body>
      <Modal.Footer>
        {!props.modalMsg && (
          <Button onClick={props.loadWeb3Modal}>
            Yes, I have a Web3 account connected.
          </Button>
        )}
        {!props.modalMsg && (
          <Button onClick={props.generateWeb3Address}>
            No. I would like a new Web3 account.
          </Button>
        )}
        {props.modalMsg && <Button onClick={props.closeModal}>All done</Button>}
      </Modal.Footer>
    </Modal>
  );
}
