import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BadgesData from "./components/dataAllBadges.json";

import OneCard from "./OneCard.js";

import { withRouter } from "react-router";

const prices = {
  level_1: 10,
  level_2: 100,
  level_3: 200,
  level_4: 500,
  level_5: 1000,
  level_6: 2000,
};

function BadgesList({ match, history }) {
  const [filter, setFilter] = useState("");
  const { params } = match;

  useEffect((w) => {
    if (params.slug && !filter) {
      setFilter(params.slug);
    }
  }, 
  [params.slug, filter]);
  
//   []);

  return (
    <Row>
      <Col>
        <div className="container">
          <div className="banner-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12">
                <div className="banner-content introFjallaOne pt-5">
                  <div
                    className="bigtextorange mb-3"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="1.5s"
                  >
                    {filter !== "team"
                      ? "All Skill Level Badges"
                      : "All Team Badges"}
                  </div>

                  <div
                    className="mdtextwhite mb-3"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="1.5s"
                  >
                    {" "}
                    {filter !== "team"
                      ? "Select a Level Skill badge. Each Skill Badge has levels in total. The level is described in the Badge description tile."
                      : "Select Team Badge, you can have first team badge for free."}{" "}
                  </div>

                  <div
                    className="smtextgray mb-3"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="1.5s"
                  >
                    This training focuses on navigating Web3 technology, not
                    accreditation. Each skill level can either be purchased or
                    earned through a series of tasks and projects. If a user
                    participates and achieves level 6, they will be granted a
                    free membership application to Web3 Legal Engineering.{" "}
                  </div>

                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

        {/* <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {!!filter &&
            BadgesData.filter((item) => item.type === filter).map(
              (x, i) => {
                return (
                  <Col lg={2} md={3} sm={3} xs={4} key={i}>
                    {false && x.tokenId}
                    <OneCard
                      type={x.type}
                      title={x.name.split("_").join(" ").toUpperCase()}
                      slug={x.name}
                      price={prices[x.type]}
                      image={x.image}
                      tokenId={x.tokenId}
                    />
                  </Col>
                );
              }
            )}
        </Row> */}


        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          {!!filter &&
            BadgesData.filter((item, index1) => item.type === filter).map(
              (x, i) => {
                return (
                  <Col lg={2} md={3} sm={3} xs={4} key={i}>
                    {false && x.tokenId}
                    <OneCard
                      type={x.type}
                      title={x.name.split("_").join(" ").toUpperCase()}
                      slug={x.name}
                      price={prices[x.type]}
                      image={x.image}
                      tokenId={x.tokenId}
                    />
                  </Col>
                );
              }
            )}
        </Row>





      </Col>
    </Row>
  );
}

export default withRouter(BadgesList);
