import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import {
     Link
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";
import "./Profile.css"
import x5b from "./abis/x5b.js"
import Web3 from 'web3';
import {convertENS} from "./ens.js"
import { OpenSeaPort, Network } from 'opensea-js'


const web3 = new Web3(Web3.givenProvider);

const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io')

const seaport = new OpenSeaPort(provider, {
    networkName: Network.Main
})

var x5BadgeContract = new web3.eth.Contract(x5b.abi, x5b.address)

// const getBadge = async (badgeId) => {
//     if (!badgeId) return {}
//     const res = await x5BadgeContract.methods.getX5BadgesById(badgeId).call();
//     const res2 = await x5BadgeContract.methods.tokenURI(badgeId).call();
//     return { ...res, url: res2}
// }

const getToken = async (id) => {
    const asset = await seaport.api.getAsset({
        tokenAddress: "0x743e27e367419d3f50855894889da135a716464e", // string
        tokenId: id, // string | number | null
    })
    // console.log("asset", asset);
    return asset
}

function Profile({ match, history }) {
    // Declare a new state variable, which we'll call "count"
    // const [count, setCount] = useState(0);
    const [address, setAddress] = useState("");
    const [rens, setRName] = useState("");
    const [badges, setBadges] = useState([]);
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(0);
    const [offset, setOffset] = useState(0);
    const { params } = match;

    useEffect(w => {
        if (params.address && !address)
        {
            setAddress(params.address)
            getBadges(params.address)
        }
    }, [params.address, address])

    const getBadges = async (address) => {
        console.log("getBadges ", convertENS);
        if (address) {
            convertENS(address).then((name) =>{
                setRName(name)
            })
            setLoading(true)
            let balance = await x5BadgeContract.methods.balanceOf(address).call();
            setBalance(parseInt(balance))
            const limit = parseInt(balance) > 25 ? 25 : parseInt(balance)
            console.log("getBadges balancex", balance, x5BadgeContract, [...Array(limit).keys()]);
            const badges = await Promise.all([...Array(limit).keys()].map(async (index) => {
                console.log("fetching badge", index);
                const tmp = await x5BadgeContract.methods.tokenOfOwnerByIndex(address, index).call();
                console.log("tokenOfOwnerByIndex",tmp);
                return await getToken(parseInt(tmp))
            }))
            console.log('badges-------------', badges);
            setBadges(badges)
            setOffset(25)
            setLoading(false)
        }
    }

    const showMore = async () => {
        setLoading(true)

        const limit = offset+25
        setOffset(limit)
        console.log("getBadges balancex", balance, x5BadgeContract, [...Array(limit).keys()]);
        const xbadges = await Promise.all([...Array(limit).keys()].slice(offset).map(async (index) => {
            console.log("fetching badge", index);
            const tmp = await x5BadgeContract.methods.tokenOfOwnerByIndex(address, index).call();
            console.log("tokenOfOwnerByIndex", tmp);
            return await getToken(parseInt(tmp))
        }))
        console.log('badges-------------', badges);
        setBadges([...badges, ...xbadges])
        setLoading(false)
    }

    return (
        <div>
            <div>
                <div className="container">
                    <div className="view-account">
                        <section className="module">
                            <div className="module-inner">
                                <div className="side-bar">
                                    <div className="user-info">
                                        <img className="img-profile img-circle img-fluid center-block" src={"https://avatars.dicebear.com/v2/gridy/" + address + ".svg"} alt="" />
                                        <ul className="meta list list-unstyled">
                                            <li className="name">
                                                <label className="label label-info">Crypto</label>
                                            </li>
                                            <li className="email"><a href={"/profile/" + address}>{rens ? rens : address}</a></li>
                                            <li className="activity">{balance} X5B</li>
                                        </ul>
                                    </div>
                                    <nav className="side-menu">
                                        <ul className="nav">
                                            <li><a href="/profile"><span className="fa fa-user" /> Profile</a></li>
                                            <li><a href="/profile"><span className="fa fa-cog" /> Settings</a></li>
                                            <li ><a href="/profile"><span className="fa fa-credit-card" /> Orders</a></li>
                                            <li><a className="active" href="/profile"><span className="fa fa-envelope" /> Skill Badges</a></li>
                                            <li><a href="/profile"><span className="fa fa-clock-o" /> Pending</a></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="content-panel">
                                    <div className="content-header-wrapper">
                                        <h2 className="title">My Skill Badges</h2>
                                        <div className="actions">
                                            <Button as={Link} to='/' className="btn btn-success"><i className="fa fa-plus" /> Buy Swag Now</Button>
                                        </div>
                                    </div>
                                    <div className="content-utilities">
                                        <div className="page-nav">
                                            <span className="indicator">View:</span>
                                            <div className="btn-group" role="group">
                                                <button className="active btn btn-default" data-toggle="tooltip" data-placement="bottom" title data-original-title="Grid View" id="drive-grid-toggle"><i className="fa fa-th-large" /></button>
                                                <button className="btn btn-default" data-toggle="tooltip" data-placement="bottom" title data-original-title="List View" id="drive-list-toggle"><i className="fa fa-list-ul" /></button>
                                            </div>
                                        </div>
                                        <div className="actions">
                                            <div className="btn-group">
                                                <button className="btn btn-default dropdown-toggle" data-toggle="dropdown" type="button" aria-expanded="false">All Items <span className="caret" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a href="/profile"><i className="fa fa-file" /> Skill Badges</a></li>
                                                    <li><a href="/profile"><i className="fa fa-file-image-o" /> Orders</a></li>
                                                    <li><a href="/profile"><i className="fa fa-file-video-o" /> Swag </a></li>
                                                    <li><a href="/profile"><i className="fa fa-folder" /> X5T</a></li>
                                                </ul>
                                            </div>
                                            <div className="btn-group">
                                                <button className="btn btn-default dropdown-toggle" data-toggle="dropdown" type="button" aria-expanded="false"><i className="fa fa-filter" /> Sorting <span className="caret" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a href="/profile">Newest first</a></li>
                                                    <li><a href="/profile">Oldest first</a></li>
                                                </ul>
                                            </div>
                                            <div className="btn-group" role="group">
                                                <button type="button" className="btn btn-default" data-toggle="tooltip" data-placement="bottom" title data-original-title="Refresh"><i className="fa fa-refresh" /></button>
                                                <button type="button" className="btn btn-default" data-toggle="tooltip" data-placement="bottom" title data-original-title="Archive"><i className="fa fa-archive" /></button>
                                                <button type="button" className="btn btn-default" data-toggle="tooltip" data-placement="bottom" title data-original-title="Report spam"><i className="fa fa-exclamation-triangle" /></button>
                                                <button type="button" className="btn btn-default" data-toggle="tooltip" data-placement="bottom" title data-original-title="Delete"><i className="fa fa-trash-o" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="drive-wrapper drive-grid-view">
                                        <div className="grid-items-wrapper">
                                            {badges.map((badge) => {
                                                return (<div className="drive-item module text-center">
                                                    <div className="drive-item-inner module-inner">
                                                        <div className="drive-item-title"><Link to={badge.externalLink}>{badge.name}</Link></div>
                                                        <div className="drive-item-thumb">
                                                            <Link to={badge.externalLink}><img className="img-fluid" src={badge.imageUrl} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                    <div className="drive-item-footer module-footer">
                                                        {badge.description}
                                                    </div>
                                                </div>)
                                            })}
                                            {balance > offset && !loading && <Button onClick={showMore} ><i className="fa fa-plus" /> Load more</Button>}
                                            {loading && <h2 className="title">Loading Skill Badges</h2>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Profile)